<template>
  <section style="padding: 0 25px">
    <el-card class="box-card">
      <div v-if="isPdfurl" id="pdfFile" style="height: 100%">
        <iframe v-if="pdfFiledom" :src="pdfFiledom" frameborder="0" width="100%" height="100%"></iframe>
      </div>
      <!-- <el-scrollbar v-if="isPdfurl" style="height:100%">
        <pdf ref="pdf" v-for="i in numPages" :key="i" :src="pdfurl" :page="i"></pdf> 
      </el-scrollbar> -->
      <div v-else class="file_null">
        <el-image :src="file_null" class="file_null_image"></el-image>
        <div class="file_null_text">{{ file_null_text }}</div>
      </div>
    </el-card>
    <!-- <el-row style="padding-top: 24px">
      <el-button type="primary" size="small" @click="pdfDownLoad()">下载</el-button>
    </el-row> -->
  </section>
</template>
<script>
// import pdf from 'vue-pdf-signature';
// import CMapReaderFactory from 'vue-pdf-signature/src/CMapReaderFactory';
export default {
  name: 'generalSituation',
  data() {
    return {
      file_null: require(`@/assets/file_null.png`),
      file_null_text: '暂未上传资料',
      numPages: null,
      pdfFiledom:null,
    };
  },
  async created() {},
     mounted() { this.$store.commit('changeCopy',true)
    // this.getNumPages();
    this.addPDF();
  },
  // components: {
  //   pdf
  // },
  computed: {
    isPdfurl() {
      let pdf = false;
      if (this.$store.state.pdfUrl) { // 存在pdf 路径
        if (this.$store.state.pdfUrl.indexOf('null') == -1) { //pdf 路径里 pdf文件不是null文件
          pdf = this.$store.state.pdfUrl.toLocaleLowerCase();
        }
      }
      return pdf;
    }
    // pdfurl() {
    //   if(this.isPdfurl){
    //     return pdf.createLoadingTask({ url: this.$store.state.pdfUrl, CMapReaderFactory, cMapPacked: true });
    //   }else{
    //     return "";
    //   }
    // }
  },
  watch: {
    '$store.state.pdfUrl': function (val) {
      // this.getNumPages();
      this.addPDF();
    }
  },
  methods: {
    addPDF() {
      if (this.isPdfurl) {
        let vm = this;
        console.log(vm.$store.state.pdfUrl);
        setTimeout(function () {
          // oss 地址下的pdf 路径前面 不要加上 前置标签  本地服务器上的pdf前面加上 前置标签
          let pdfUrl = vm.$store.state.pdfUrl.indexOf('magicalign.com') == -1 ? '' : '/pdfjs/web/viewer.html?file=';

          vm.pdfFiledom = `${pdfUrl}${vm.$store.state.pdfUrl}`;
        }, 1000);
      }else {
        this.pdfFiledom = null;
      }
    }
    // // 计算pdf页码总数
    // getNumPages() {
    //   if(this.isPdfurl){
    //     let loadingTask = pdf.createLoadingTask({ url: this.$store.state.pdfUrl, CMapReaderFactory, cMapPacked: true });
    //     loadingTask.promise.then(pdf => {
    //       this.numPages = pdf.numPages;
    //     });
    //   }
    // },
    // pdfDownLoad() {
    //   if(this.isPdfurl){
    //     let a = document.createElement('a');
    //     a.setAttribute('href', this.$store.state.pdfUrl);
    //     a.setAttribute('download', this.$store.state.pdfUrl.split('/').pop());
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //   }
    // }
  }
};
</script>
<style scoped lang="less">
button {
  width: 140px;
  height: 30px;
}
.box-card {
  background: #2e2f33;
  border: 1px;
  height: calc(100vh - 350px);

  span {
    width: 100%;
    height: 730px;
  }
  /deep/ .el-card__body {
    height: calc(100vh - 350px);
    padding: 0px;
  }
}
// /deep/ .el-scrollbar__view {
//   height: calc(100vh - 460px);
// }

.file_null {
  text-align: center;
  width: 100px;
  height: 100px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.file_null_image {
  height: auto !important;

  /deep/ .el-image__inner {
    width: 50px;
  }
}
.file_null_text {
  padding-top: 10px;
  color: #aaadb1;
}
</style>
