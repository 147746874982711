<template>
  <el-row class="chat-box">
    <el-col :span="24" class="object-box">
      <div>
        <el-avatar :src="companyLogo"></el-avatar>
      </div>
      <div class="content-box" v-show="type === 1">
        <div>
          <span>{{ time }}</span>
        </div>
        <div class="content">
          <span>{{ content }}</span>
        </div>
      </div>
      <div v-show="type === 2">
        <div style="margin-left: 10px">
          <span>{{ time }}</span>
        </div>
        <div class="file-box">
          <!-- img表示div -->
          <div class="float-left">
            <el-image style="width: 70px; height: 80px" :src="calcImgUrl(fileUrl)" :fit="calcImgFit(fileUrl)" />
          </div>
          <!-- 文件描述div -->
          <div style="width: 150px" class="float-right">
            <div>
              <p style="height: 60px; word-wrap: break-word; overflow-y: hidden" :title="dealFileName(fileUrl)">
                {{ dealFileName(fileUrl) }}
              </p>
            </div>
            <div>
              <div class="float-left">
                <span>{{ size }}</span>
              </div>
              <div class="float-right"><span class="download" @click="getFile(fileUrl)">下载</span></div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'companyChatBox',
  data() {
    return {
      companyLogo: require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/头像2.png'),
      fileLogo: require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/文件1.png')
    };
  },
  props: {
    content: String,
    time: String,
    type: {
      type: Number
    },
    fileUrl: String,
    size: String
  },
  computed: {
    dealFileName() {
      return (url) => {
        // 获取文件blob数据并保存
        var num = url.lastIndexOf('/') + 1;
        //把参数和文件名分割开
        return url.substring(num).split('?')[0];
      };
    },
    calcImgUrl() {
      let vm = this;
      return (url) => {
        let name = url.split('.').pop().toLowerCase();
        if (['png', 'jpg', 'jpeg'].indexOf(name) != -1) {
          return url;
        } else {
          return vm.fileLogo;
        }
      };
    },
    calcImgFit() {
      return (url) => {
        let name = url.split('.').pop().toLowerCase();
        if (['png', 'jpg', 'jpeg'].indexOf(name) != -1) {
          return 'fill';
        } else {
          return 'none';
        }
      };
    }
  },
  methods: {
    download(link, name) {
      let eleLink = document.createElement('a');
      eleLink.download = name;
      eleLink.style.display = 'none';
      eleLink.href = link;
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink);
    },
    getFile(link) {
      console.log(link);
      if (!link) {
        return;
      }
      var num =  link.lastIndexOf('/') + 1;
      var name = link.substring(num).split('?')[0];
      let vm = this;
      this.$http({
        url: link,
        responseType: 'blob'
      })
        .then((response) => {
          const link = URL.createObjectURL(response.data);
          vm.download(link, name);
        })
        .catch(() => {
          this.$message.error('下载失败');
        });
    }
  }
};
</script>
<style lang="less" scoped>
.chat-box {
  margin-bottom: 14px;
  .object-box {
    display: -webkit-flex;
    display: flex;
    flex-wrap: nowrap;
    .file-box {
      margin-left: 10px;
      width: 248px;
      height: 84px;
      background-color: #2f3035;
      padding: 16px;

      span {
        font-size: 14px;
      }

      .download {
        color: #1a91ff;
        cursor: pointer;
      }
    }
  }
  .content-box {
    margin-left: 10px;
    color: #aaadb1;
    .content {
      background-color: #2f3035;
      padding: 5px;
      width: 350px;
      span {
        word-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
</style>