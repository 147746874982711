<template>
  <el-dialog
    title="Bolton 分析"
    :visible.sync="dialogVisible"
    width="506px"
    top="20vh"
    :modal="modal"
    :close-on-click-modal="clickModal"
  >
    <div class="bolton-box content-border">
      <el-row class="title">
        <el-col :span="12"><span class="color-text">超过</span></el-col>
        <el-col :span="12"><span class="color-text">值</span></el-col>
      </el-row>
      <el-row class="content">
        <el-col :span="12"><span>下颌多于3-3</span></el-col>
        <el-col :span="12">{{ fromatNumber(this.summary[0]) }}毫米</el-col>
        <el-col :span="12"><span>下颌多于6-6</span></el-col>
        <el-col :span="12">{{ fromatNumber(this.summary[1]) }}毫米</el-col>
      </el-row>
    </div>
    <div class="bolton-box content-border center-box">
      <el-row class="title child-title">
        <el-col :span="12"><span>上颌右侧</span></el-col>
        <el-col :span="12"><span>上颌左侧</span></el-col>
      </el-row>
      <el-row class="title">
        <el-col :span="6"><span class="color-text">牙位识别码</span></el-col>
        <el-col :span="6"><span class="color-text">宽度</span></el-col>
        <el-col :span="6"><span class="color-text">牙位识别码</span></el-col>
        <el-col :span="6"><span class="color-text">宽度</span></el-col>
      </el-row>
      <!-- 上颌右侧 -->
      <el-row>
        <el-col :span="12">
          <el-row>
            <div v-for="(item, index) in urData" :key="index">
              <el-col :span="12"
                ><span>{{ item[0] }}</span></el-col
              >
              <el-col :span="12"
                ><span>{{ fromatNumber(item[1]) }}毫米</span></el-col
              >
            </div>
          </el-row>
        </el-col>
        <!-- 上颌左侧 -->
        <el-col :span="12">
          <el-row>
            <div v-for="(item, index) in ulData" :key="index">
              <el-col :span="12"
                ><span>{{ item[0] }}</span></el-col
              >
              <el-col :span="12"
                ><span>{{ fromatNumber(item[1]) }}毫米</span></el-col
              >
            </div>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="bolton-box">
      <el-row class="title child-title">
        <el-col :span="12"><span style="font-size: 16px">下颌右侧</span></el-col>
        <el-col :span="12"><span style="font-size: 16px">下颌左侧</span></el-col>
      </el-row>
      <el-row class="title">
        <el-col :span="6"><span class="color-text">牙位识别码</span></el-col>
        <el-col :span="6"><span class="color-text">宽度</span></el-col>
        <el-col :span="6"><span class="color-text">牙位识别码</span></el-col>
        <el-col :span="6"><span class="color-text">宽度</span></el-col>
      </el-row>
      <!-- 下颌右侧 -->
      <el-row>
        <el-col :span="12">
          <el-row>
            <div v-for="(item, index) in lrData" :key="index">
              <el-col :span="12"
                ><span>{{ item[0] }}</span></el-col
              >
              <el-col :span="12"
                ><span>{{ fromatNumber(item[1]) }}毫米</span></el-col
              >
            </div>
          </el-row>
        </el-col>
        <!-- 下颌左侧 -->
        <el-col :span="12">
          <el-row>
            <div v-for="(item, index) in llData" :key="index">
              <el-col :span="12"
                ><span>{{ item[0] }}</span></el-col
              >
              <el-col :span="12"
                ><span>{{ fromatNumber(item[1]) }}毫米</span></el-col
              >
            </div>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import {
  GetBoltonIndex36,
  GetBoltonIndexUR,
  GetBoltonIndexUL,
  GetBoltonIndexLR,
  GetBoltonIndexLL
} from '../../../../render/renderES';
export default {
  name: 'boltonWindow',
  data() {
    return {
      modal: false, //遮罩层标记
      clickModal: false, //点击空白隐藏标记
      /**
       * 汇总信息
       */
      summary: [],
      /**
       * 上颌右侧
       */
      urData: [],
      /**
       * 上颌左侧
       */
      ulData: [],
      /**
       * 下颌右侧
       */
      lrData: [],
      /**
       * 下颌左侧
       */
      llData: []
    };
  },
  props: {
    visible: {
      //汇总信息
      type: Boolean
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('updateBoltonChecked');
        return this.$emit('update:visible', value);
      }
    },
    fromatNumber() {
      return (val) => {
        if (val) {
          return val.toFixed(2);
        } else {
          return '--';
        }
      };
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        this.summary = GetBoltonIndex36();
        this.urData = GetBoltonIndexUR();
        this.ulData = GetBoltonIndexUL();
        this.lrData = GetBoltonIndexLR();
        this.llData = GetBoltonIndexLL();
      }
    }
  },
  methods: {
    /**
     * 获取bolton数据
     */
    featchData() {
      //TO DO
    }
  },
     mounted() { this.$store.commit('changeCopy',true)}
};
</script>
<style scoped lang="less">
/deep/ .el-dialog__header {
  background-color: #171719;
  padding: 8px 20px;
  span {
    color: #ffffff;
    font-size: 16px;
  }
  .el-dialog__headerbtn {
    top: 13px;
    i {
      color: #ffffff;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 36px 0px 10px 24px;
  font-size: 16px;
  font-family: 'SourceHanSans-CN-Regular';
}
.color-text {
  color: #1a91ff;
}
.bolton-box {
  padding: 0px 0px 24px 0px;
  .title {
    padding-bottom: 10px;
  }
  .child-title {
    padding-top: 24px;
  }
}
.center-box {
  padding-bottom: 15px;
}
.content-border {
  border-bottom: 1px solid #dddddd;
}
</style>
