<template>
  <el-card class="box-card photo-card">
    <div class="photo-img">
      <el-image v-if="this.dataList.length > 0" :src="dataList[index]" fit="contain"> </el-image>
      <div v-if="this.dataList.length == 0" class="file_null">
        <el-image :src="file_null" class="file_null_image"></el-image>
        <div class="file_null_text">{{file_null_text}}</div>
      </div>
    </div>
    <el-row type="flex" class="row-bg" justify="center" v-if="this.dataList.length > 0">
      <el-col :span="6" class="text-center row-icon"><i class="el-icon-arrow-left" @click="prevIndex()"></i></el-col>
      <el-col :span="6" class="text-center row-text">{{ index + 1 }}/{{ dataList.length }}</el-col>
      <el-col :span="6" class="text-center row-icon"><i class="el-icon-arrow-right" @click="nextIndex()"></i></el-col>
    </el-row>
  </el-card>
</template>
<script>
export default {
  name: 'photoImg',
  props: {
    currentIndex: {
      type: Number
    },
    dataList: {
      type: Array
    }
  },
  data() {
    return {
      file_null: require(`@/assets/file_null.png`),
      file_null_text: '暂未上传资料'
    };
  },
  async created() {},
     mounted() { this.$store.commit('changeCopy',true)},
  components: {},
  computed: {
    index: {
      get() {
        return this.currentIndex;
      },
      set(value) {
        this.$emit('update:currentIndex', value);
      }
    }
  },
  methods: {
    prevIndex() {
      console.log('prevIndex');
      if (this.index > 0) {
        this.index--;
      }
    },
    nextIndex() {
      console.log('nextIndex');
      if (this.index < this.dataList.length - 1) {
        this.index++;
      }
    }
  }
};
</script>
<style scoped lang="less">
.text-center {
  text-align: center;
}
.el-icon-arrow-left,
.el-icon-arrow-right {
  cursor: pointer;
}
.photo-card {
  background: #2e2f33;
  border: 1px;
  height: calc(100vh - 400px);

  .el-card__body {
    padding-bottom: 18px;
  }

  .photo-img {
    height: calc(100vh - 460px);

    .el-image {
      width: 100%;
      height: 100%;
    }
  }

  .row-bg {
    color: white;
    padding-top: 10px;

    .row-icon {
      width: 10px;
      height: 16px;
    }
    .row-text {
      padding: 0 72px;
      width: auto;
    }
  }
}

.file_null {
  text-align: center;
  width: 100px;
  height: 100px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.file_null_image {
  height: auto !important;

  /deep/ .el-image__inner {
    width: 50px;
  }
}
.file_null_text {
  padding-top: 10px;
  color: #aaadb1;
}
</style>
