<template>
  <div>
    <el-radio-group v-model="radioValue" size="medium">
      <el-radio-button label="rotate">旋转</el-radio-button>
      <el-radio-button label="move">平移</el-radio-button>
    </el-radio-group>
  </div>
</template>
<script>
import { SetTrackballMode } from '../../../../render/renderES'; // 孙懿修改
export default {
  name: 'switchBtton',
  props: {
    value: {
      type: String
    }
  },
  computed: {
    radioValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
        if (value === "rotate") { // 孙懿修改
          SetTrackballMode(0);
        } else if (value === "move") {
          SetTrackballMode(1);
        }
      }
    }
  }
};
</script>