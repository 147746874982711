<template>
  <section>
    <el-row>
      <el-col :span="24" style="text-align: center">
        <a :class="textBtnClass('F')" @click="textBtnClick($event)" data-type="F">画像照</a>
        <a :class="textBtnClass('T')" @click="textBtnClick($event)" data-type="T">口内照</a>
        <a :class="textBtnClass('X')" @click="textBtnClick($event)" data-type="X">X光片</a>
      </el-col>
    </el-row>
    <el-row style="padding: 0px 25px;">
      <el-col :span="24">
        <photoImg
          :currentIndex.sync="photoData[tabSelected].currentIndex"
          :dataList.sync="photoData[tabSelected].dataList"
        />
      </el-col>
    </el-row>
  </section>
</template>
<script>
import photoImg from '@/views/common/FourSmile/rightMenu/photoImg';
export default {
  name: 'photo',
  data() {
    return {
      caseId: null,
      tabSelected: 'F',
      dataImg: {
        currentIndex: 0,
        dataList: []
      },
      photoData: {
        X: {
          currentIndex: 0,
          dataList: []
        },
        F: {
          currentIndex: 0,
          dataList: []
        },
        T: {
          currentIndex: 0,
          dataList: []
        }
      }
    };
  },
  async created() {},
     mounted() { this.$store.commit('changeCopy',true)
    this.getPhotos();
  },
  components: {
    photoImg
  },
  watch: {
    '$store.state.planId': function(val) {
      this.getPhotos();
    }
  },
  computed: {
    getCaseId(){
      return this.$store.state.caseId;
    },
    getPlanId(){
      return this.$store.state.planId;
    },
    /**
     * 计算按钮式样
     */
    textBtnClass() {
      return type => {
        if (this.tabSelected === type) {
          return { 'text-btn': true, 'text-btn-active': true };
        } else {
          return { 'text-btn': true };
        }
      };
    }
  },
  methods: {
    textBtnClick(e) {
      this.tabSelected = e.target.getAttribute('data-type');
      this.dataImg = this.photoData[this.tabSelected];
    },
    handleClick(tab, event) {
      console.log('right menu', tab, event);
    },
    getPhotos() {
      let vm = this;
      this.photoData = {
        X: {
          currentIndex: 0,
          dataList: []
        },
        F: {
          currentIndex: 0,
          dataList: []
        },
        T: {
          currentIndex: 0,
          dataList: []
        }
      };
      this.$http({
        url: '/plan/getPhotos',
        method: 'POST',
        data: {
          caseId: vm.getCaseId,
          planId: vm.getPlanId
        }
      })
        .then(({ data }) => {
          // this.LoadingClose();
          if (data.code == 200) {
            const dataList = JSON.parse(data.data);
            console.log(dataList);
            console.log(11111111111111111111111111111111111111);
            dataList.forEach(item => {
              if (vm.photoData[item.type]) {
                vm.photoData[item.type].dataList.push(item.fileUrl);
              }
            });
          } else {
            console.log('error');
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style scoped lang="less">
.text-btn {
  display: inline-block;
  width: 66px;
  text-align: center;
  cursor: pointer;
  margin: 0 20px 5px;
  color: white;
}
.text-btn-active {
  color: #409eff;
  border-bottom: 2px solid #409eff;
  line-height: 35px;
}
</style>
