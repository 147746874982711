var camera, // 相机对象
    cameraPersp,
    cameraOrtho,
    cameraRig,
    cameraTarget, // 相机朝向目标位置,三维空间点对象
    scene, // 场景对象
    renderer, // 渲染器
    toothMaterial, // 牙齿材质
    gingivaMaterial, // 牙龈材质
    attachMaterial, // 附件材质
    cutterMaterial, // 切割型附件材质
    light1, // 光源1
    light2, // 光源2
    light,
    nTotalStep; // 病例总步数
var caseno; // 病例编号--唯一标识符
var parserMaf = {}; // MAF文件解析对象
var ww = window.innerWidth, // 浏览器有效页面宽度,不跟着最大化调整,和初始大小有关
    wh = window.innerHeight*0.7; // 浏览器有效页面高度
var vecTeeth = new Map(); // 牙齿模型容器
var vecTeeth_Root = new Map(); // 牙根模型容器
var vecAttachment = new Map(); // 附件模型容器
var vecUpGingiva = new Map(); // 上颌牙龈模型容器
var vecLowGingiva = new Map(); // 下颌牙龈模型容器
var vecUpGingiva_Base = new Map(); // 上颌牙龈底座容器
var vecLowGingiva_Base = new Map(); // 下颌牙龈底座容器
var stepIndex = 0; // 当前所在步数
var mafModel = {}; // MAF模型数据集
var mapStepinfo_Offset = new Map(); // 模型的偏移数据容器,仅牙齿及附件,含切割
var mapStepinfo_Quaternion = new Map(); // 模型的旋转数据容器,仅牙齿及附件,含切割
var stepBar = []; // 初始化进度条的格子数量所用的数组
var mafUrl; // MAF文件的链路地址
var bIsMobile = false; // 是否用手机访问页面
var jawDisplayState = 2; // 上,下,双颌的显示状态
var nCameraDistance = 0; // 相机到朝向目标的默认距离,用于视角切换
var controls; // 轨迹球控制器对象
var ptBoxCenter = new window.THREE.Vector3(0, 0, 0); // 上下颌牙龈整体包围盒中心点

var gingivaBuild = new window.Module.CGingivaBuild();

var funCallbackObj = {};

// var geom1 = new window.THREE.Geometry();
// var geom2 = new window.THREE.Geometry();
// var geom3 = new window.THREE.Geometry();
// var geom4 = new window.THREE.Geometry();
// gingivaMaterial

// var gingivaMaterial1 = gingivaMaterial;
// var gingivaMaterial2 = gingivaMaterial;
// var gingivaMaterial3 = gingivaMaterial;
// var gingivaMaterial4 = gingivaMaterial;

var G_lowGingivaMesh_Base = {};//new window.THREE.Mesh(geom1, gingivaMaterial1);
var G_upperGingivaMesh_Base = {};//new window.THREE.Mesh(geom2, gingivaMaterial2);
var G_lowGingivaMesh = {};//new window.THREE.Mesh(geom3, gingivaMaterial3);
var G_upperGingivaMesh = {};//new window.THREE.Mesh(geom4, gingivaMaterial4);

var geomInitUpper = {};
var geomInitLower = {};

/*Shader脚本区块*/
const gingivaVertShader = `
varying vec3 v3Normal;
varying vec3 v3WorldPos;
varying vec3 v3EyeDirCameraSpace;
varying float fLightDistance;

void main() {
    vec4 v4Normal = modelViewMatrix * vec4( normal, 0.0 );
    v3Normal = normalize(v4Normal.xyz);
    vec4 v4CameraSpacePos = modelViewMatrix * vec4( position, 1.0 );
    vec4 v4WorldPos = modelMatrix * vec4( position, 1.0 );
    v3WorldPos = v4WorldPos.xyz;
    v3EyeDirCameraSpace = -v4CameraSpacePos.xyz;
    vec4 v4WorldOriginInCameraSpace = viewMatrix * vec4( 0.0,0.0,0.0, 1.0 );
    vec3 v3WorldOriginInCameraSpace = v4WorldOriginInCameraSpace.xyz;
    fLightDistance = length(v3WorldOriginInCameraSpace - normalize(v3WorldOriginInCameraSpace)*100.0 + v3EyeDirCameraSpace);
    gl_Position = projectionMatrix * v4CameraSpacePos;
}`

const gingivaFragShader = `
#define PI 3.1415926535897932384626433832795
#define ETA_AIR 1.0
#define ETA_SALIVA 1.33
#define ROUGHNESS_SALIVA 0.12
#define F0_SALIVA 0.02
#define HUE 0.975
#define SATURATION 1.047
#define VALUE 1.0
#define CONTRAST 1.25

uniform vec3 color;
uniform float metallic;
uniform float roughness;
uniform float shiness;
uniform float f0;
uniform int teethsize;
uniform vec3 toothcenter0;
uniform vec3 toothcenter1;
uniform vec3 toothcenter2;
uniform vec3 toothcenter3;
uniform vec3 toothcenter4;
uniform vec3 toothcenter5;
uniform vec3 toothcenter6;
uniform vec3 toothcenter7;
uniform vec3 toothcenter8;
uniform vec3 toothcenter9;
uniform vec3 toothcentera;
uniform vec3 toothcenterb;
uniform vec3 toothcenterc;
uniform vec3 toothcenterd;
uniform vec3 toothcentere;
uniform vec3 toothcenterf;

varying vec3 v3Normal;
varying vec3 v3WorldPos;
varying vec3 v3EyeDirCameraSpace;
varying float fLightDistance;

vec3 RGB2HSV(vec3 v3RGB)
{
    vec4 v4K = vec4(0.0, -1.0/3.0, 2.0/3.0, -1.0);
    vec4 v4P = mix(vec4(v3RGB.bg, v4K.wz), vec4(v3RGB.gb, v4K.xy), step(v3RGB.b, v3RGB.g));
    vec4 v4Q = mix(vec4(v4P.xyw, v3RGB.r), vec4(v3RGB.r, v4P.yzx), step(v4P.x, v3RGB.r));
    float fD = v4Q.x - min(v4Q.w, v4Q.y);
    float fE = 1.0e-10;
    return vec3(abs(v4Q.z + (v4Q.w - v4Q.y)/(6.0*fD + fE)), fD/(v4Q.x + fE), v4Q.x);
}

vec3 HSV2RGB(vec3 v3HSV)
{
    vec4 v4K = vec4(1.0, 2.0/3.0, 1.0/3.0, 3.0);
    vec3 v3P = abs(fract(v3HSV.xxx + v4K.xyz)*6.0 - v4K.www);
    return v3HSV.z*mix(v4K.xxx, clamp(v3P - v4K.xxx, 0.0, 1.0), v3HSV.y);
}

vec3 DisplaySetting(vec3 v3RGB)
{
    vec3 v3HSV = RGB2HSV(v3RGB);
    v3HSV.x = mod(v3HSV.x + HUE, 1.0);
    v3HSV.y *= SATURATION;
    v3HSV.z *= VALUE;
    vec3 v3Color = HSV2RGB(v3HSV);
    vec3 v3MeanLum = vec3(VALUE*0.36);
    return mix(v3Color, mix(v3MeanLum, v3Color, CONTRAST), 0.5);
}

vec3 ACEToneMapping(vec3 v3Color, float fAdaptedLum)
{
    float fA = 2.51;
    float fB = 0.03;
    float fC = 2.43;
    float fD = 0.59;
    float fE = 0.14;
    v3Color *= fAdaptedLum;
    return (v3Color*(fA*v3Color + fB)) / (v3Color*(fC*v3Color + fD) + fE);
}

float Attenute(float fDist)
{
    float fFactor = 2.0;
    float fConst = 1.0;
    float fLinear = 0.045;
    float fQuad = 0.0075;
    fDist *= fFactor;
    return 1.0 / (fConst + fLinear*fDist + fQuad*fDist*fDist);
}

float SoftLight(float fBase, float fBlend)
{
    return (fBlend<0.5) ? (2.0*fBase*fBlend + fBase*fBase*(1.0 - 2.0*fBlend)) : (sqrt(fBase)*(2.0*fBlend - 1.0) + 2.0*fBase*(1.0 - fBlend));
}

vec3 SoftLight(vec3 v3Base, vec3 v3Blend)
{
    return vec3(SoftLight(v3Base.r,v3Blend.r), SoftLight(v3Base.g,v3Blend.g), SoftLight(v3Base.b,v3Blend.b));
}

vec3 SoftLight(vec3 v3Base, vec3 v3Blend, float fOpacity)
{
    return mix(v3Base, SoftLight(v3Base, v3Blend), fOpacity);
}

vec3 Gradient(vec3 v3Base)
{
    vec3 v3Target = vec3(1.0, 0.685, 0.645);
    int nIndex = 1;
    float fDist = length(toothcenter0 - v3WorldPos);
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenter1 - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenter2 - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenter3 - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenter4 - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenter5 - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenter6 - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenter7 - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenter8 - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenter9 - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcentera - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenterb - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenterc - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenterd - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcentere - v3WorldPos));nIndex++;
    if (nIndex < teethsize) fDist = min(fDist, length(toothcenterf - v3WorldPos));
    float fWeight = 1.0 - smoothstep(8.0*0.45, 8.0, fDist);
    return SoftLight(v3Base, v3Target, 2.0*fWeight);
}

float DistributionGGX(float fNdotH, float fRoughness, float fWeight)
{
    float fSqrRoughness = fRoughness*fRoughness;
    float fPow4Roughness = fSqrRoughness*fSqrRoughness;
    float fSqrNdotH = fNdotH*fNdotH;
    float fNominator = fPow4Roughness;
    float fDenominator = max(fSqrNdotH*(fPow4Roughness - 1.0) + 1.0, 0.0001);
    return fNominator * ((1.0 - fWeight)/(PI*fDenominator) + fWeight/(PI*fDenominator*fDenominator));
}

float GeometryGGX(float fNdotL, float fNdotV, float fRoughness)
{
    float fA = 0.5 + 0.5*fRoughness;
    fA = fA*fA;
    float fB = 1.0 - fA;
    float fNominator = 2.0*fNdotL*fNdotV;
    float fDenominator = fNdotV*(fNdotL*fB + fA) + fNdotL*(fNdotV*fB + fA);
    return fNominator / max(fDenominator, 0.0001);
}

vec3 FresnelSchlick(float fCosine, vec3 v3F0)
{
    float fBase = 1.0 - fCosine;
    float fSqrBase = fBase*fBase;
    fBase *= fSqrBase*fSqrBase;
    return v3F0 + (vec3(1.0) - v3F0)*fBase;
}

vec3 SpecularIntensity(float fNdotL, float fNdotV, float fNdotH, float fHdotL, vec3 v3F0, float fRoughness, float fShiness)
{
    float fNDF = DistributionGGX(fNdotH, fRoughness, fShiness);
    float fG = GeometryGGX(fNdotL, fNdotV, fRoughness);
    vec3 v3F = FresnelSchlick(fHdotL, v3F0);
    vec3 v3Nominator = fNDF*fG*v3F;
    float fDenominator = max(4.0*fNdotV*fNdotL, 0.0001);
    return v3Nominator / fDenominator;
}

vec3 DiffuseIntensity(float fNdotL, float fNdotV, float fVdotH, vec3 v3BaseColor, float fRoughness)
{
    float fFD90 = 0.5 + 2.0*fRoughness*fVdotH*fVdotH;
    float fMulti = 1.0 + (fFD90 - 1.0)*pow(1.0 - fNdotL, 5.0);
    fMulti *= 1.0 + (fFD90 - 1.0)*pow(1.0 - fNdotV, 5.0);
    return v3BaseColor*fMulti/PI;
}

void main() {
    float fEtaRatio = ETA_AIR / ETA_SALIVA;
    vec3 v3GingivaColor = Gradient(color);
    vec3 v3F0 = mix(vec3(f0), v3GingivaColor, metallic);
    vec3 v3LightPos = vec3(0.0, 50.0, 0.0) + v3EyeDirCameraSpace;
    vec3 v3LightDir = normalize(v3LightPos);
    vec3 v3ViewDir = normalize(v3EyeDirCameraSpace);
    vec3 v3LightDir12 = -refract(-v3LightDir, v3Normal, fEtaRatio);
    vec3 v3LightDir21 = -refract(-v3ViewDir, v3Normal, fEtaRatio);
    vec3 v3HalfVPlusL = normalize(v3LightDir12 + v3LightDir21);
    float fNdotL = max(dot(v3Normal, v3LightDir), 0.0);
    float fNdotV = max(dot(v3Normal, v3ViewDir), 0.0);
    float fNdotH = max(dot(v3Normal, v3HalfVPlusL), 0.0);
    float fHdotL = max(dot(v3HalfVPlusL, v3LightDir), 0.0);
    float fNdotL12 = max(dot(v3Normal, v3LightDir12), 0.0);
    float fNdotL21 = max(dot(v3Normal, v3LightDir21), 0.0);
    float fHdotL21 = max(dot(v3HalfVPlusL, v3LightDir12), 0.0);

    vec3 v3Specular = SpecularIntensity(fNdotL12, fNdotL21, fNdotH, fHdotL21, v3F0, roughness, 0.25);
    vec3 v3Diffuse = DiffuseIntensity(fNdotL12, fNdotL21, fHdotL21, v3GingivaColor, roughness);
    vec3 v3F21 = 1.0 - FresnelSchlick(fNdotL21, vec3(F0_SALIVA));
    vec3 v3F12 = 1.0 - FresnelSchlick(fNdotV, vec3(F0_SALIVA));
    float fkD = 1.0 - metallic;

    vec3 v3Out = fkD*v3Diffuse + v3Specular;
    v3Out *= v3F21*v3F12;
    // v3Out += SpecularIntensity(fNdotL, fNdotV, fNdotH, fHdotL, vec3(F0_SALIVA), ROUGHNESS_SALIVA, shiness);
    v3Out *= fNdotL*Attenute(fLightDistance)/Attenute(165.0);
    v3Out += v3GingivaColor*0.3;
    v3Out = DisplaySetting(v3Out);
    v3Out = ACEToneMapping(v3Out, 0.36);
    v3Out = max(v3Out, vec3(0.0));
    v3Out = pow(v3Out, vec3(1.0/2.2));
    gl_FragColor = vec4( v3Out,1.0 );
}
`

const toothVertShader = `
varying vec3 v3Normal;
varying vec3 v3WorldPos;
varying vec3 v3EyeDirCameraSpace;
varying float fLightDistance;

void main() {
    vec4 v4Normal = modelViewMatrix * vec4( normal, 0.0 );
    v3Normal = normalize(v4Normal.xyz);
    vec4 v4CameraSpacePos = modelViewMatrix * vec4( position, 1.0 );
    vec4 v4WorldPos = modelMatrix * vec4( position, 1.0 );
    v3WorldPos = v4WorldPos.xyz;
    v3EyeDirCameraSpace = -v4CameraSpacePos.xyz;
    vec4 v4WorldOriginInCameraSpace = viewMatrix * vec4( 0.0,0.0,0.0, 1.0 );
    vec3 v3WorldOriginInCameraSpace = v4WorldOriginInCameraSpace.xyz;
    fLightDistance = length(v3WorldOriginInCameraSpace - normalize(v3WorldOriginInCameraSpace)*100.0 + v3EyeDirCameraSpace);
    gl_Position = projectionMatrix * v4CameraSpacePos;
}
`

const toothFragShader = `
#define PI 3.1415926535897932384626433832795
#define ETA_AIR 1.0
#define ETA_SALIVA 1.33
#define ETA_ENAMEL 1.62
#define ETA_DENTIN 1.25
#define ROUGHNESS_SALIVA 0.1
#define F0_SALIVA 0.02
#define HUE 0.975
#define SATURATION 1.047
#define VALUE 1.0
#define CONTRAST 1.25

uniform vec3 enamelcolor;
uniform vec3 dentincolor;
uniform float enamelmetallic;
uniform float enamelroughness;
uniform float dentinmetallic;
uniform float dentinroughness;
uniform float shiness;
uniform float f0;
uniform vec3 toothcenter;

varying vec3 v3Normal;
varying vec3 v3WorldPos;
varying vec3 v3EyeDirCameraSpace;
varying float fLightDistance;

vec3 RGB2HSV(vec3 v3RGB)
{
    vec4 v4K = vec4(0.0, -1.0/3.0, 2.0/3.0, -1.0);
    vec4 v4P = mix(vec4(v3RGB.bg, v4K.wz), vec4(v3RGB.gb, v4K.xy), step(v3RGB.b, v3RGB.g));
    vec4 v4Q = mix(vec4(v4P.xyw, v3RGB.r), vec4(v3RGB.r, v4P.yzx), step(v4P.x, v3RGB.r));
    float fD = v4Q.x - min(v4Q.w, v4Q.y);
    float fE = 1.0e-10;
    return vec3(abs(v4Q.z + (v4Q.w - v4Q.y)/(6.0*fD + fE)), fD/(v4Q.x + fE), v4Q.x);
}

vec3 HSV2RGB(vec3 v3HSV)
{
    vec4 v4K = vec4(1.0, 2.0/3.0, 1.0/3.0, 3.0);
    vec3 v3P = abs(fract(v3HSV.xxx + v4K.xyz)*6.0 - v4K.www);
    return v3HSV.z*mix(v4K.xxx, clamp(v3P - v4K.xxx, 0.0, 1.0), v3HSV.y);
}

vec3 DisplaySetting(vec3 v3RGB)
{
    vec3 v3HSV = RGB2HSV(v3RGB);
    v3HSV.x = mod(v3HSV.x + HUE, 1.0);
    v3HSV.y *= SATURATION;
    v3HSV.z *= VALUE;
    vec3 v3Color = HSV2RGB(v3HSV);
    vec3 v3MeanLum = vec3(VALUE*0.36);
    return mix(v3Color, mix(v3MeanLum, v3Color, CONTRAST), 0.5);
}

vec3 ACEToneMapping(vec3 v3Color, float fAdaptedLum)
{
    float fA = 2.51;
    float fB = 0.03;
    float fC = 2.43;
    float fD = 0.59;
    float fE = 0.14;
    v3Color *= fAdaptedLum;
    return (v3Color*(fA*v3Color + fB)) / (v3Color*(fC*v3Color + fD) + fE);
}

float Attenute(float fDist)
{
    float fFactor = 2.0;
    float fConst = 1.0;
    float fLinear = 0.045;
    float fQuad = 0.0075;
    fDist *= fFactor;
    return 1.0 / (fConst + fLinear*fDist + fQuad*fDist*fDist);
}

float Lighten(float fBase, float fBlend)
{
    return  max(fBase, fBlend);
}

float Darken(float fBase, float fBlend)
{
    return  min(fBase, fBlend);
} 

float PinLight(float fBase, float fBlend)
{
    return (fBlend<0.5) ? Darken(fBase, 2.0*fBlend) : Lighten(fBase, 2.0*(fBlend - 0.5));
}

vec3 PinLight(vec3 v3Base, vec3 v3Blend)
{
    return vec3(PinLight(v3Base.r,v3Blend.r), PinLight(v3Base.g,v3Blend.g), PinLight(v3Base.b,v3Blend.b));
}

vec3 PinLight(vec3 v3Base, vec3 v3Blend, float fOpacity)
{
    return mix(v3Base, PinLight(v3Base, v3Blend), fOpacity);
}

vec3 RootColor(vec3 v3Base)
{
    vec3 v3Target = HSV2RGB(vec3(0.06, 1.0, 0.5));
    float f1 = smoothstep(0.0, 12.0, abs(v3WorldPos.z - toothcenter.z));
    float f2 = (70.0 - clamp(v3WorldPos.y, 0.0, 70.0)) / 70.0;
    f2 *= max(1.0 - cos(f2*PI*0.5), 0.6);
    return PinLight(v3Base, v3Target, f1*f2);
}

float DistributionGGX(float fNdotH, float fRoughness, float fWeight)
{
    float fSqrRoughness = fRoughness*fRoughness;
    float fPow4Roughness = fSqrRoughness*fSqrRoughness;
    float fSqrNdotH = fNdotH*fNdotH;
    float fNominator = fPow4Roughness;
    float fDenominator = max(fSqrNdotH*(fPow4Roughness - 1.0) + 1.0, 0.0001);
    return fNominator * ((1.0 - fWeight)/(PI*fDenominator) + fWeight/(PI*fDenominator*fDenominator));
}

float GeometryGGX(float fNdotL, float fNdotV, float fRoughness)
{
    float fA = 0.5 + 0.5*fRoughness;
    fA = fA*fA;
    float fB = 1.0 - fA;
    float fNominator = 2.0*fNdotL*fNdotV;
    float fDenominator = fNdotV*(fNdotL*fB + fA) + fNdotL*(fNdotV*fB + fA);
    return fNominator / max(fDenominator, 0.0001);
}

vec3 FresnelSchlick(float fCosine, vec3 v3F0)
{
    float fBase = 1.0 - fCosine;
    float fSqrBase = fBase*fBase;
    fBase *= fSqrBase*fSqrBase;
    return v3F0 + (vec3(1.0) - v3F0)*fBase;
}

vec3 F0(float fEta1, float fEta2, float fMetallic, vec3 v3Color)
{
    float fF0 = (fEta1 - fEta2)/(fEta1 + fEta2);
    fF0 = fF0*fF0;
    return mix(vec3(fF0), v3Color, fMetallic);
}

vec3 SpecularIntensity(float fNdotL, float fNdotV, float fNdotH, float fHdotL, vec3 v3F0, float fRoughness, float fShiness)
{
    float fNDF = DistributionGGX(fNdotH, fRoughness, fShiness);
    float fG = GeometryGGX(fNdotL, fNdotV, fRoughness);
    vec3 v3F = FresnelSchlick(fHdotL, v3F0);
    vec3 v3Nominator = fNDF*fG*v3F;
    float fDenominator = max(4.0*fNdotV*fNdotL, 0.0001);
    return v3Nominator / fDenominator;
}

vec3 DiffuseIntensity(float fNdotL, float fNdotV, float fVdotH, vec3 v3BaseColor, float fRoughness)
{
    float fFD90 = 0.5 + 2.0*fRoughness*fVdotH*fVdotH;
    float fMulti = 1.0 + (fFD90 - 1.0)*pow(1.0 - fNdotL, 5.0);
    fMulti *= 1.0 + (fFD90 - 1.0)*pow(1.0 - fNdotV, 5.0);
    return v3BaseColor*fMulti/PI;
}

void main() {
    float fEtaRatioAirSaliva = ETA_AIR / ETA_SALIVA;
    float fEtaRatioSalivaEnamel = ETA_SALIVA / ETA_ENAMEL;
    vec3 v3LightPos = vec3(0.0, 50.0, 0.0) + v3EyeDirCameraSpace;
    vec3 v3LightDir = normalize(v3LightPos);
    vec3 v3ViewDir = normalize(v3EyeDirCameraSpace);
    vec3 v3HalfVPlusL = normalize(v3ViewDir + v3LightDir);
    float fNdotL = max(dot(v3Normal, v3LightDir), 0.0);
    float fNdotV = max(dot(v3Normal, v3ViewDir), 0.0);
    float fNdotH = max(dot(v3Normal, v3HalfVPlusL), 0.0);
    float fHdotL = max(dot(v3HalfVPlusL, v3LightDir), 0.0);
    vec3 v3F0Saliva = vec3(F0_SALIVA);
    vec3 v3SpecularSaliva = SpecularIntensity(fNdotL, fNdotV, fNdotH, fHdotL, v3F0Saliva, ROUGHNESS_SALIVA, shiness);

    vec3 v3LightDirEnamel = -refract(-v3LightDir, v3Normal, fEtaRatioAirSaliva);
    vec3 v3ViewDirEnamel = -refract(-v3ViewDir, v3Normal, fEtaRatioAirSaliva);
    vec3 v3HalfEnamel = normalize(v3LightDirEnamel + v3ViewDirEnamel);
    float fNdotLEnamel = max(dot(v3Normal, v3LightDirEnamel), 0.0);
    float fNdotVEnamel = max(dot(v3Normal, v3ViewDirEnamel), 0.0);
    float fNdotHEnamel = max(dot(v3Normal, v3HalfEnamel), 0.0);
    float fVdotHEnamel = max(dot(v3ViewDirEnamel, v3HalfEnamel), 0.0);
    vec3 v3EnamelColor = RootColor(enamelcolor);
    vec3 v3F0Enamel = F0(ETA_ENAMEL, ETA_SALIVA, enamelmetallic, v3EnamelColor);
    vec3 v3SpecularEnamel = SpecularIntensity(fNdotLEnamel, fNdotVEnamel, fNdotHEnamel, fVdotHEnamel, v3F0Enamel, enamelroughness, 0.5);
    vec3 v3DiffuseEnamel = DiffuseIntensity(fNdotLEnamel, fNdotVEnamel, fVdotHEnamel, v3EnamelColor, enamelroughness);

    vec3 v3LightDirDentin = -refract(-v3LightDir, v3Normal, fEtaRatioSalivaEnamel);
    vec3 v3ViewDirDentin = -refract(-v3ViewDir, v3Normal, fEtaRatioSalivaEnamel);
    vec3 v3HalfDentin = normalize(v3LightDirDentin + v3ViewDirDentin);
    float fNdotLDentin = max(dot(v3Normal, v3LightDirDentin), 0.0);
    float fNdotVDentin = max(dot(v3Normal, v3ViewDirDentin), 0.0);
    float fNdotHDentin = max(dot(v3Normal, v3HalfDentin), 0.0);
    float fVdotHDentin = max(dot(v3ViewDirDentin, v3HalfDentin), 0.0);
    vec3 v3F0Dentin = F0(ETA_DENTIN, ETA_ENAMEL, dentinmetallic, dentincolor);
    vec3 v3SpecularDentin = SpecularIntensity(fNdotLDentin, fNdotVDentin, fNdotHDentin, fVdotHDentin, v3F0Dentin, dentinroughness, 0.5);
    vec3 v3DiffuseDentin = DiffuseIntensity(fNdotLDentin, fNdotVDentin, fVdotHDentin, dentincolor, dentinroughness);

    vec3 v3kSSaliva = FresnelSchlick(fNdotL, v3F0Saliva);
    vec3 v3kSEnamel = FresnelSchlick(fNdotLEnamel, v3F0Enamel);
    float fkDEnamel = 1.0 - enamelmetallic;
    // vec3 v3kSDentin = FresnelSchlick(fNdotLDentin, v3F0Dentin);
    float fkDDentin = 1.0 - dentinmetallic;

    vec3 v3TransmitSE = 1.0 - v3kSSaliva;
    v3TransmitSE *= 1.0 - FresnelSchlick(fNdotVEnamel, v3F0Saliva);
    vec3 v3TransmitED = 1.0 - v3kSEnamel;
    v3TransmitED *= 1.0 - FresnelSchlick(fNdotVDentin, v3F0Enamel);

    vec3 v3Out = vec3( 0.0 );
    v3Out += v3SpecularSaliva;
    v3Out += (fkDEnamel*v3DiffuseEnamel + v3SpecularEnamel)*v3TransmitSE;
    v3Out += (fkDDentin*v3DiffuseDentin + v3SpecularDentin)*v3TransmitED*v3TransmitSE;
    v3Out *= fNdotL*Attenute(fLightDistance)/Attenute(200.0);
    v3Out += enamelcolor*0.3;
    v3Out = DisplaySetting(v3Out);
    v3Out = ACEToneMapping(v3Out, 0.36);
    v3Out = max(v3Out, vec3(0.0));
    v3Out = pow(v3Out, vec3(1.0/2.2));
    gl_FragColor = vec4( v3Out,1.0 );
}
`

const attachVertShader = `
varying vec3 v3Normal;
varying vec3 v3WorldPos;
varying vec3 v3EyeDirCameraSpace;
varying float fLightDistance;

void main() {
    vec4 v4Normal = modelViewMatrix * vec4( normal, 0.0 );
    v3Normal = normalize(v4Normal.xyz);
    vec4 v4CameraSpacePos = modelViewMatrix * vec4( position, 1.0 );
    vec4 v4WorldPos = modelMatrix * vec4( position, 1.0 );
    v3WorldPos = v4WorldPos.xyz;
    v3EyeDirCameraSpace = -v4CameraSpacePos.xyz;
    vec4 v4WorldOriginInCameraSpace = viewMatrix * vec4( 0.0,0.0,0.0, 1.0 );
    vec3 v3WorldOriginInCameraSpace = v4WorldOriginInCameraSpace.xyz;
    fLightDistance = length(v3WorldOriginInCameraSpace - normalize(v3WorldOriginInCameraSpace)*100.0 + v3EyeDirCameraSpace);
    gl_Position = projectionMatrix * v4CameraSpacePos;
}
`

const attachFragShader = `
#define PI 3.1415926535897932384626433832795
#define ROUGHNESS 0.1
#define HUE 0.975
#define SATURATION 1.047
#define VALUE 1.0
#define CONTRAST 1.25

varying vec3 v3Normal;
varying vec3 v3WorldPos;
varying vec3 v3EyeDirCameraSpace;
varying float fLightDistance;

uniform vec3 attachcolor;
uniform float metallic;

vec3 RGB2HSV(vec3 v3RGB)
{
    vec4 v4K = vec4(0.0, -1.0/3.0, 2.0/3.0, -1.0);
    vec4 v4P = mix(vec4(v3RGB.bg, v4K.wz), vec4(v3RGB.gb, v4K.xy), step(v3RGB.b, v3RGB.g));
    vec4 v4Q = mix(vec4(v4P.xyw, v3RGB.r), vec4(v3RGB.r, v4P.yzx), step(v4P.x, v3RGB.r));
    float fD = v4Q.x - min(v4Q.w, v4Q.y);
    float fE = 1.0e-10;
    return vec3(abs(v4Q.z + (v4Q.w - v4Q.y)/(6.0*fD + fE)), fD/(v4Q.x + fE), v4Q.x);
}

vec3 HSV2RGB(vec3 v3HSV)
{
    vec4 v4K = vec4(1.0, 2.0/3.0, 1.0/3.0, 3.0);
    vec3 v3P = abs(fract(v3HSV.xxx + v4K.xyz)*6.0 - v4K.www);
    return v3HSV.z*mix(v4K.xxx, clamp(v3P - v4K.xxx, 0.0, 1.0), v3HSV.y);
}

vec3 DisplaySetting(vec3 v3RGB)
{
    vec3 v3HSV = RGB2HSV(v3RGB);
    v3HSV.y *= SATURATION;
    v3HSV.z *= VALUE;
    vec3 v3Color = HSV2RGB(v3HSV);
    return v3Color;
}

vec3 ACEToneMapping(vec3 v3Color, float fAdaptedLum)
{
    float fA = 2.51;
    float fB = 0.03;
    float fC = 2.43;
    float fD = 0.59;
    float fE = 0.14;
    v3Color *= fAdaptedLum;
    return (v3Color*(fA*v3Color + fB)) / (v3Color*(fC*v3Color + fD) + fE);
}

float Attenute(float fDist)
{
    float fFactor = 2.0;
    float fConst = 1.0;
    float fLinear = 0.045;
    float fQuad = 0.0075;
    fDist *= fFactor;
    return 1.0 / (fConst + fLinear*fDist + fQuad*fDist*fDist);
}

float DistributionBeckmann(float fNdotH, float fRoughness)
{
    float fSqrRoughness = fRoughness*fRoughness;
    float fPow4Roughness = fSqrRoughness*fSqrRoughness;
    float fSqrNdotH = fNdotH*fNdotH;
	float fPow4NdotH = fSqrNdotH*fSqrNdotH;
    float fNominator = (fSqrNdotH - 1.0) / max(0.0001, fPow4Roughness*fSqrNdotH);
	fNominator = exp(fNominator);
    float fDenominator = max(PI*fPow4Roughness*fPow4NdotH, 0.0001);
    return fNominator / fDenominator;
}

float GeometryGGX(float fNdotL, float fNdotV, float fRoughness)
{
    float fA = 0.5 + 0.5*fRoughness;
    fA = fA*fA;
    float fB = 1.0 - fA;
    float fNominator = 2.0*fNdotL*fNdotV;
    float fDenominator = fNdotV*(fNdotL*fB + fA) + fNdotL*(fNdotV*fB + fA);
    return fNominator / max(fDenominator, 0.0001);
}

vec3 FresnelSchlick(float fCosine, vec3 v3F0)
{
    float fBase = 1.0 - fCosine;
    float fSqrBase = fBase*fBase;
    fBase *= fSqrBase*fSqrBase;
    return v3F0 + (vec3(1.0) - v3F0)*fBase;
}

vec3 SpecularIntensity(float fNdotL, float fNdotV, float fNdotH, float fHdotL, vec3 v3F0, float fRoughness)
{
    float fNDF = DistributionBeckmann(fNdotH, fRoughness);
    float fG = GeometryGGX(fNdotL, fNdotV, fRoughness);
    vec3 v3F = FresnelSchlick(fHdotL, v3F0);
    vec3 v3Nominator = fNDF*fG*v3F;
    float fDenominator = max(4.0*fNdotV*fNdotL, 0.0001);
    return v3Nominator / fDenominator;
}

vec3 DiffuseIntensity(float fNdotL, float fNdotV, float fVdotH, vec3 v3BaseColor, float fRoughness)
{
    float fFD90 = 0.5 + 2.0*fRoughness*fVdotH*fVdotH;
    float fMulti = 1.0 + (fFD90 - 1.0)*pow(1.0 - fNdotL, 5.0);
    fMulti *= 1.0 + (fFD90 - 1.0)*pow(1.0 - fNdotV, 5.0);
    return v3BaseColor*fMulti/PI;
}

void main(){
	vec3 v3LightPos = vec3(0.0, 50.0, 0.0) + v3EyeDirCameraSpace;
    vec3 v3LightDir = normalize(v3LightPos);
    vec3 v3ViewDir = normalize(v3EyeDirCameraSpace);
    vec3 v3HalfVPlusL = normalize(v3ViewDir + v3LightDir);
    float fNdotL = max(dot(v3Normal, v3LightDir), 0.0);
    float fNdotV = max(dot(v3Normal, v3ViewDir), 0.0);
    float fNdotH = max(dot(v3Normal, v3HalfVPlusL), 0.0);
    float fHdotL = max(dot(v3HalfVPlusL, v3LightDir), 0.0);
	vec3 v3F0 = vec3(0.04);
	vec3 v3Specular = SpecularIntensity(fNdotL, fNdotV, fNdotH, fHdotL, v3F0, ROUGHNESS);
	vec3 v3Diffuse = DiffuseIntensity(fNdotL, fNdotV, fHdotL, attachcolor, ROUGHNESS);
	vec3 v3kS = FresnelSchlick(fNdotL, v3F0);
	float fkD = 1.0 - metallic;
	vec3 v3Out = fkD*v3Diffuse + v3Specular;
	v3Out *= fNdotL*Attenute(fLightDistance)/Attenute(250.0);
	v3Out += attachcolor*0.3;
    v3Out = DisplaySetting(v3Out);
    v3Out = ACEToneMapping(v3Out, 0.36);
    v3Out = max(v3Out, vec3(0.0));
    v3Out = pow(v3Out, vec3(1.0/2.2));
    gl_FragColor = vec4( v3Out,1.0 );	
}
`

// gingivaMaterial = new window.THREE.ShaderMaterial({
//     uniforms: {
//         color: {
//             value: new window.THREE.Color(0xff7a58)
//         },
//         metallic: {
//             value: 0.0
//         },
//         roughness: {
//             value: 0.5
//         },
//         shiness: {
//             value: 0.25
//         },
//         f0: {
//             value: 0.04
//         },
//         depthTest: false
//     },

//     vertexShader: gingivaVertShader,
//     fragmentShader: gingivaFragShader
// });

// toothMaterial = new window.THREE.ShaderMaterial({
//     uniforms: {
//         enamelcolor: {
//             value: new window.THREE.Color(0xF9EACC)
//         },
//         dentincolor: {
//             value: new window.THREE.Color(0xFFFFE0)
//         },
//         enamelmetallic: {
//             value: 0.2
//         },
//         enamelroughness: {
//             value: 0.5
//         },
//         dentinmetallic: {
//             value: 0.3
//         },
//         dentinroughness: {
//             value: 0.1
//         },
//         shiness: {
//             value: 0.75
//         },
//         f0: {
//             value: 0.04
//         },
//         depthTest: false
//     },

//     vertexShader: toothVertShader,
//     fragmentShader: toothFragShader
// });

// attachMaterial = new window.THREE.ShaderMaterial({
//     uniforms: {
//         attachcolor: {
//             value: new window.THREE.Color(0xD21A1A)
//         },
//         metallic: {
//             value: 0.2
//         },
//         depthTest: false
//     },

//     vertexShader: attachVertShader,
//     fragmentShader: attachFragShader
// });

gingivaMaterial = new window.THREE.MeshPhysicalMaterial({
    // color: new window.THREE.Color("rgb(181,105,92)"),//new window.THREE.Color(0xff7a58),
    metalness: 0.0,
    roughness: 0.5,
    clearcoat: 0.8,
    clearcoatRoughness: 0.0,
    reflectivity: 0.5,
    vertexColors: window.THREE.VertexColors,
});

toothMaterial = new window.THREE.MeshPhysicalMaterial( {
    // color: new window.THREE.Color("rgb(184,170,157)").offsetHSL(0,0,0.025),
    metalness: 0.0,
    roughness: 2.0,
    clearcoat: 0.8,
    clearcoatRoughness: 0.1,
    reflectivity: 0.1,
    vertexColors: window.THREE.VertexColors,
});

attachMaterial = new window.THREE.MeshPhysicalMaterial( {
    color: new window.THREE.Color(1,0.5,0.5).offsetHSL(0,-0.05,-0.05),
    metalness: 0.0,
    roughness: 0.5,
    clearcoat: 0.8,
    clearcoatRoughness: 0.2,
    reflectivity: 0.5,
});

function SoftLightChannel(fBase, fBlend) {
    return (fBlend < 0.5) ? (2.0 * fBase * fBlend + fBase * fBase * (1.0 - 2.0 * fBlend)) : (Math.sqrt(fBase) * (2.0 * fBlend - 1.0) + 2.0 * fBase * (1.0 - fBlend));
}

function SoftLight(v3Base, v3Blend) {
    return new window.THREE.Color(SoftLightChannel(v3Base.r, v3Blend.r), SoftLightChannel(v3Base.g, v3Blend.g), SoftLightChannel(v3Base.b, v3Blend.b));
}

function SoftLightOpacity(v3Base, v3Blend, fOpacity) {
    return v3Base.lerp(SoftLight(v3Base, v3Blend), fOpacity);
}

function PinLightChannel(fBase, fBlend) {
    return (fBlend < 0.5) ? Math.min(fBase, 2.0 * fBlend) : Math.max(fBase, 2.0 * (fBlend - 0.5));
}

function PinLight(v3Base, v3Blend) {
    return new window.THREE.Color(PinLightChannel(v3Base.r, v3Blend.r), PinLightChannel(v3Base.g, v3Blend.g), PinLightChannel(v3Base.b, v3Blend.b));
}

function PinLightOpacity(v3Base, v3Blend, fOpacity) {
    return v3Base.lerp(PinLight(v3Base, v3Blend), fOpacity);
}

function smoothstep(a, b, x) {
    if (x <= a) return 0;
    if (x >= b) return 1;
    let t = (x - a) / (b - a);
    return t * t * (3.0 - (2.0 * t));
}

function GingivaGradient(cm_vert, bupper, vertexcolor, step) {
    if (vertexcolor === undefined) vertexcolor = [];
    let len = cm_vert.length;
    if (len == 0) return vertexcolor;

    if (step === undefined) step = 0;
    let teethcenters = [];
    if (bupper) {
        for (let key of mapStepinfo_Offset.keys()) {
            if (key < 30) {
                teethcenters.push(mapStepinfo_Offset.get(key)[step]);
            }
        }

    } else {
        for (let key of mapStepinfo_Offset.keys()) {
            if (key > 30) {
                teethcenters.push(mapStepinfo_Offset.get(key)[step]);
            }
        }
    }

    let p = new window.THREE.Vector3();
    let teethsize = teethcenters.length;
    let mindistance, dist2, weight;
    for (let i = 0; i != len; i++) {
        p.copy(cm_vert[i]);
        mindistance = 1000000;
        for (let j = 0; j != teethsize; j++) {
            dist2 = teethcenters[j].distanceToSquared(p);
            if (mindistance > dist2) {
                mindistance = dist2;
            }
        }
        weight = 1.0 - smoothstep(8.0 * 0.45, 8.0, Math.sqrt(mindistance));
        vertexcolor[i] = SoftLightOpacity(new window.THREE.Color("rgb(176, 90, 100)"), new window.THREE.Color("rgb(126, 161, 151)"), 2.0 * weight);
    }
    return vertexcolor;
}

function ToothGradient(cm_vert, vertexnorm, vertexcurv, vertexcolor) {
    if (vertexcolor === undefined) vertexcolor = [];
    let len = cm_vert.length;
    if (len == 0) return vertexcolor;
    if (len != vertexnorm.length) return vertexcolor;
    if (len != vertexcurv.length) return vertexcolor;

    let box = new window.THREE.Box3();
    box.setFromPoints(cm_vert);

    let simplex = new SimplexNoise();
    let p = new window.THREE.Vector3();
    let q = new window.THREE.Vector3();
    let weight, ndotup, curv, noise;
    let color = new window.THREE.Color();
    const crown = new window.THREE.Color("rgb(185,180,165)").offsetHSL(0,0,0.025);
    const root = new window.THREE.Color("rgb(198,99,28)");
    const groove = new window.THREE.Color("rgb(105,97,86)");
    for (let i = 0; i != len; i++) {
        color.copy(crown);
        ndotup = vertexnorm[i].z;
        curv = vertexcurv[i];
        if (ndotup < -0.866 && curv < 0) {
            weight = Math.max(curv, -3) / 3;
            weight *= (ndotup + 0.866) * 7.46;
            weight = (0.2) * smoothstep(0, 1, weight);
            color.lerp(groove, weight);
        }

        q.copy(cm_vert[i]);
        noise = simplex.noise3d(q.x, q.y, q.z) * 0.1;
        box.getParameter(q, p);
        weight = smoothstep(0.5 + noise, 2, p.z);
        vertexcolor[i] = PinLightOpacity(color.clone(), root, weight);

        if (ndotup < -0.707) {
            weight = Math.max(-1, Math.min(0.5, curv));
            weight *= (ndotup + 0.707) / (-0.293);
            vertexcolor[i].multiplyScalar(smoothstep(-10, 10, weight) / 0.5);
        }
    }
}

// 播放模型动画
export const playModel = function (step) {
    stepIndex = step;
    // console.log(step);
    playTeeth(step);
    playAttachment(step);
    shiftGingivaVisible(step, jawDisplayState);
}
// 切换附件显示与否
export const shiftAttachVisible = function (visible) {
    vecAttachment.forEach(function (value, key) {
        value.visible = visible;
    });
    if (visible) {
        playAttachment(stepIndex);
    }
}
// 渲染
export const render = function () {

    updateScreenSize();
    controls.update();
    renderer.render(scene, camera);
}
// 初始化数据
export const initStl = function (id, url, isMobile) {
   
    mafUrl = url; // 设定链路
    caseno = id;
    bIsMobile = isMobile; // 设定模式
    if (scene != undefined){
        for(let i=scene.children.length - 1; i >= 0; i--){
            let obj = scene.children[i];
            if(obj instanceof window.THREE.Mesh){
                obj.material.dispose();
                obj.geometry.dispose();
            }
            scene.remove(obj);
        }
        scene.remove();
    }
    if (renderer!= undefined){
        while (renderer.domElement.lastChild){
            renderer.domElement.removeChild(renderer.domElement.lastChild);
        }
        renderer.dispose();
    }
    let canvascollection = document.getElementsByClassName('scene');
    let canvasscene = canvascollection[0];
    renderer = new window.THREE.WebGLRenderer({canvas: canvasscene, antialias: true, alpha: true, logarithmicDepthBuffer: false}); // 解决Zfighting
    renderer.setPixelRatio(window.devicePixelRatio); // 提示清晰度
    renderer.setSize(ww, wh);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = window.THREE.PCFSoftShadowMap;
    renderer.sortObjects = false;

    nCameraDistance = bIsMobile?750:300;
    cameraTarget = new window.THREE.Vector3(0, 0, 0);

    cameraPersp = new window.THREE.PerspectiveCamera(10, ww / wh, nCameraDistance - 100, nCameraDistance + 100);
    cameraPersp.position.set(0, - nCameraDistance, 0);
    cameraPersp.lookAt(cameraTarget);
    cameraPersp.updateProjectionMatrix();

    cameraOrtho = new window.THREE.OrthographicCamera(-30*ww/wh,30*ww/wh, 30, -30, nCameraDistance - 100, nCameraDistance + 100);
    cameraOrtho.position.set(0, - nCameraDistance, 0);
    cameraOrtho.lookAt(cameraTarget);
    cameraOrtho.updateProjectionMatrix();

    cameraRig = new window.THREE.Group();
    cameraRig.add( cameraPersp );
    cameraRig.add( cameraOrtho );
    camera = cameraPersp;
    scene = new window.THREE.Scene();
    scene.add(cameraRig);
    addShadowedLight(0xe6ebf0);
    // cutterMaterial = new window.THREE.MeshPhongMaterial({
    //     color: 0x3CAEFF, // 切割型附件颜色,请不要随意更改
    //     specular: 0x080808,
    //     shininess: 10
    // });

    ajaxRequest(); // 发送MAF文件解析请求(ajax异步)

    controls = new window.THREE.TrackballControls(camera, canvasscene); // 生成轨迹球场景控制器
    controls.rotateSpeed = 3;
    controls.zoomSpeed = 0.8;
    controls.panSpeed = 0.3;
    controls.maxDistance = nCameraDistance + 100;
    controls.minDistance = 50;
    controls.dynamicDampingFactor = 0.2;
    controls.position0.set(0, 0, 0);
    controls.up0.set(0, 0, 1); // set a new up vector
    controls.reset();
    controls.staticMoving = true; // 关闭惯性
    controls.mouseButtons = { LEFT: window.THREE.MOUSE.ROTATE, MIDDLE: window.THREE.MOUSE.ZOOM, RIGHT: window.THREE.MOUSE.PAN };
    jawDisplayState = 2; // 初始化颌面显示状态
    animate();
}
// 更新屏幕大小
function updateScreenSize() { // 正常来说应该只走onWindowResize,但是无法截获用户调整浏览器屏幕尺寸
    var winWidth =  localStorage.getItem('winWidth') || 0;
    // console.log('本地参数',winWidth)
    ww = window.innerWidth -winWidth; // 不跟着最大化调整,和初始大小有关
    wh = window.innerHeight*0.7;
    if ((cameraPersp.position.lengthSq()) > 2000 * 2000) { // 缩小限制
        cameraPersp.position.x *= 0.95;
        cameraPersp.position.y *= 0.95;
        cameraPersp.position.z *= 0.95;
    }
    cameraPersp.aspect = ww / wh;
    cameraPersp.near = Math.max(10, cameraPersp.position.length() - 50);
    cameraPersp.far = cameraPersp.position.length() + 50;
    cameraPersp.updateProjectionMatrix();

    if ((cameraOrtho.position.lengthSq()) > 2000 * 2000) { // 缩小限制
        cameraOrtho.position.x *= 0.95;
        cameraOrtho.position.y *= 0.95;
        cameraOrtho.position.z *= 0.95;
    }
    cameraOrtho.near = Math.max(1, Math.min(50, cameraPersp.position.length() - 50));
    cameraOrtho.far = Math.max(200, cameraPersp.position.length() + 50);
    cameraOrtho.updateProjectionMatrix();
    const e = camera.matrixWorld.elements;
    let offset = new window.THREE.Vector3(e[4] + e[8], e[5] + e[9], e[6] + e[10]).multiplyScalar(50);
    light.position.copy(offset.add(camera.position).normalize().multiplyScalar(50));
    renderer.setSize(ww, wh);
    controls.handleResize();
}
// 增加平行光源
function addShadowedLight(color) {
    light = new window.THREE.DirectionalLight(color, 0.5);
    let offset = new window.THREE.Vector3(0, -50, -50);
    offset.add(camera.position).normalize().multiplyScalar(50);
    light.position.copy(offset);
    // light.position.set(0, 50, 50);

    light.castShadow = true;
    light.shadow.camera.near = 10;
    light.shadow.camera.far = 100;
    light.shadow.camera.left = -50;
    light.shadow.camera.right = 50;
    light.shadow.camera.top = 50;
    light.shadow.camera.bottom = -50;
    light.shadow.mapSize.width = 2048;
    light.shadow.mapSize.height = 2048;
    light.shadow.bias = -0.0001;
    light.shadow.radius = 1.5;
    scene.add(light);

    // var helper = new window.THREE.CameraHelper( light.shadow.camera );
    // scene.add( helper );
    var ambientLight = new window.THREE.AmbientLight(color, 0.8);
    scene.add(ambientLight);
}
// 动画渲染函数,原理未知
function animate() {
    requestAnimationFrame(animate);
    render();
    if (funCallbackObj.callback && typeof funCallbackObj.callback === 'function'){
        funCallbackObj.callback();
    }
}
// 切换视角按钮点击事件
export const pointModel = function (btnIndex) {
    if (camera == null) {
        return;
    }

    if (btnIndex == 0) { // 双颌
        jawDisplayState = 2;
        showDoubleJaw();
    } else if (btnIndex == 1) { // 单颌
        if (jawDisplayState == 2 || jawDisplayState == -1) {
            jawDisplayState = 1;
            showSingleJaw(true);
        } else if (jawDisplayState == 1) {
            jawDisplayState = -1;
            showSingleJaw(false);
        }
    } else if (btnIndex == 2) { // 左侧
        cameraPersp.position.set(-1.1 * nCameraDistance, 0, 0);
        cameraPersp.rotation.set(1.57, -1.57, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = 0;
        cameraPersp.up.z = -1;
        cameraOrtho.position.set(-1.1 * nCameraDistance, 0, 0);
        cameraOrtho.rotation.set(1.57, -1.57, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = 0;
        cameraOrtho.up.z = -1;
        controls.target.copy(controls.target0);
    } else if (btnIndex == 3) { // 正面
        cameraPersp.position.set(0, - nCameraDistance, 0); // 正面(0, -400, 0)
        cameraPersp.rotation.set(0, 0, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = 0;
        cameraPersp.up.z = -1;
        cameraOrtho.position.set(0, - nCameraDistance, 0); // 正面(0, -400, 0)
        cameraOrtho.rotation.set(0, 0, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = 0;
        cameraOrtho.up.z = -1;
        controls.target.copy(controls.target0);
    } else if (btnIndex == 4) { // 右侧
        cameraPersp.position.set(1.1 * nCameraDistance, 0, 0);
        cameraPersp.rotation.set(1.57, 1.57, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = 0;
        cameraPersp.up.z = -1;
        cameraOrtho.position.set(1.1 * nCameraDistance, 0, 0);
        cameraOrtho.rotation.set(1.57, 1.57, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = 0;
        cameraOrtho.up.z = -1;
        controls.target.copy(controls.target0);
    } else if (btnIndex == 5) { // 下颌
        jawDisplayState = -1;
        showSingleJaw(false);
        cameraPersp.position.set(0, 0, - nCameraDistance);
        cameraPersp.rotation.set(0, 0, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = 1;
        cameraPersp.up.z = 0;
        cameraOrtho.position.set(0, 0, - nCameraDistance);
        cameraOrtho.rotation.set(0, 0, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = 1;
        cameraOrtho.up.z = 0;
        controls.target.copy(controls.target0);
    } else if (btnIndex == 6) { // 上颌
        jawDisplayState = 1;
        showSingleJaw(true);
        cameraPersp.position.set(0, 0, nCameraDistance);
        cameraPersp.rotation.set(0, 0, 0);
        cameraPersp.up.x = 0;
        cameraPersp.up.y = -1;
        cameraPersp.up.z = 0;
        cameraOrtho.position.set(0, 0, nCameraDistance);
        cameraOrtho.rotation.set(0, 0, 0);
        cameraOrtho.up.x = 0;
        cameraOrtho.up.y = -1;
        cameraOrtho.up.z = 0;
        controls.target.copy(controls.target0);
    }
    playAttachment(stepIndex);

    // 看向世界中心
    cameraTarget = new window.THREE.Vector3(0, 0, 0);
    cameraPersp.lookAt(cameraTarget);
    cameraPersp.updateProjectionMatrix();
    cameraOrtho.lookAt(cameraTarget);
    cameraOrtho.updateProjectionMatrix(); 
    // camera.updateProjectionMatrix();
    render();
}
// 调试用时间日志输出函数
function getTime() {
    var date1 = new Date();
    var year = date1.getFullYear();
    var month = date1.getMonth() + 1;
    var day = date1.getDate();
    var hours = date1.getHours();
    var minutes = date1.getMinutes();
    var seconds = date1.getSeconds();
    var mSeconds = date1.getMilliseconds();
    return(year + "年" + month + "月" + day + "日" + hours + ":" + minutes + ":" + seconds + "." + mSeconds);
}
// 发送ajax请求,下载并解析maf文件



// $.ajax({
//     type:"GET",
//     url:"service.php?number="+$("#keyword").val(),
//     dataType:"json", 预期服务器返回数据的类型
//     success:function(data){
//        if(data.success){
//            $("searchResult").html(data.msg);
//        }else{
//            $("#searchResult").html("出现错误：" + data.msg);
//        }
//     },
//     error:function(jqXHR){
//        aler("发生错误："+ jqXHR.status);
//     }
// });

function ajaxRequest() {
    var url = mafUrl;
    // if (url.length == 0) {
    //     alert("Empty Input");
    //     return;
    // }
    // ActiveXObject

    
    var ajax = null;
    if (window.ActiveXObject) {
        ajax = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else if (window.XMLHttpRequest) {
        ajax = new XMLHttpRequest();
    } else {
        alert("Browser Not Support XMLHTTP");
        return;
    }
    if (ajax != null) {
        Free();
        ajax.onreadystatechange = function () {
            if (this.readyState == 4 && (this.status == 200)) { // 若status为0则是读取本地病例,若status为200则是读取服务器上的病例
                if (this.response && this.response.byteLength && this.response.byteLength > 5) { // console.log("MAF文件已经拿到,开始解析"+getTime());
                    var arrMaf = new Uint8Array(this.response);
                    var strSign = "";
                    var filename = this.responseURL;
                    var nSourceLen = 0;
                    for (var i = 0; i < 6; i++) {
                        strSign += String.fromCharCode(arrMaf[i]);
                    }
                    if (strSign == "AB.SIP") {
                        var hex_length = "";
                        for (i = 6; i < 10; i++) {
                            var r = arrMaf[i];
                            r = r.toString(16);
                            if (r.length == 1) 
                                r = "0" + r;
                            
                            hex_length = r + "" + hex_length;
                        }
                        nSourceLen = parseInt(hex_length, 16);
                        parserMaf.SetFileConfig(filename, nSourceLen);

                        window.LZMA.decompress(arrMaf.slice(6), function on_decompress_complete(result) { // console.log("解析"+getTime());
                            parserMaf.ReadUncompressBuffer(result);
                            ajaxAttachMaf(filename);
                        }, function on_decompress_progress_update(percent) {});
                    } else {
                        nSourceLen = arrMaf.length;
                        parserMaf.SetFileConfig(filename, nSourceLen);
                        parserMaf.ReadUncompressBuffer(arrMaf);
                        ajaxAttachMaf(filename);
                    }
                } else {
                    alert("File Not Exist");
                }
            }
        };

        ajax.open("GET", url, true);
        ajax.responseType = "arraybuffer";

        ajax.setRequestHeader('If-Modified-Since', '0');

        ajax.contentType = ajax.send();
    }
}

function ajaxAttachMaf(filename){
    var ajax = null;
    if (window.ActiveXObject) {
        ajax = new window.ActiveXObject("Microsoft.XMLHTTP");
    } else if (window.XMLHttpRequest) {
        ajax = new XMLHttpRequest();
    } else {
        alert("Browser Not Support XMLHTTP");
        return;
    }
    if (ajax != null) {
        var arrAttachmentType = [];
        var attachmafname = "";
        var nAttachIndex = 0;
        ajax.onreadystatechange = function () {
            if (this.readyState == 4 && (this.status == 200)) { // 若status为0则是读取本地病例,若status为200则是读取服务器上的病例
                if (this.response && this.response.byteLength && this.response.byteLength > 5) {
                    var arrMaf = new Uint8Array(this.response);
                    // attachmafname = this.responseURL.split("/").reverse()[0];
                    var strSign = "";
                    for (var i = 0; i < 6; i++) {
                        strSign += String.fromCharCode(arrMaf[i]);
                    }
                    if (strSign == "AB.SIP") {
                        var hex_length = "";
                        for (i = 6; i < 10; i++) {
                            var r = arrMaf[i];
                            r = r.toString(16);
                            if (r.length == 1) r = "0" + r;                            
                            hex_length = r + "" + hex_length;
                        }

                        window.LZMA.decompress(arrMaf.slice(6), function on_decompress_complete(result) { // console.log("解析"+getTime());
                            let result1 = parserMaf.ReadUncompressAttachMaf(result, attachmafname);
                            if (result1 == 1) console.log("attachment " + attachmafname + " parsed");
                            nAttachIndex++;
                            if (nAttachIndex == arrAttachmentType.length) {
                                parserMAFData(filename, "yes", parserMaf);
                            } else {
                                attachmafname = arrAttachmentType[nAttachIndex];
                                if(mafUrl.indexOf('easysmile.magicalign')!=-1) {
                                    ajax.open("GET", "http://easysmile.magicalign.com/output/attachment/" + attachmafname, true);
                                }else {
                                    ajax.open("GET", "http://case.magicalign.com/output/attachment/" + attachmafname, true);
                                    // ajax.open("GET", "/output/attachment/" + attachmafname, true);
                                }
                                ajax.responseType = "arraybuffer";    
                                ajax.setRequestHeader('If-Modified-Since', '0');    
                                ajax.contentType = ajax.send();
                            }

                        }, function on_decompress_progress_update(percent) {});
                    }
                }
            }
        };

        arrAttachmentType = parserMaf.GetAttachMafName().split("?");
        console.log(arrAttachmentType);
        if (arrAttachmentType.length != 0 && arrAttachmentType[0] != ""){
            attachmafname = arrAttachmentType[nAttachIndex];
            if(mafUrl.indexOf('easysmile.magicalign')!=-1) {
                ajax.open("GET", "http://easysmile.magicalign.com/output/attachment/" + attachmafname, true);
            }else {
                ajax.open("GET", "http://case.magicalign.com/output/attachment/" + attachmafname, true);
                // ajax.open("GET", "/output/attachment/" + attachmafname, true);
            }

            ajax.responseType = "arraybuffer";    
            ajax.setRequestHeader('If-Modified-Since', '0');
            ajax.contentType = ajax.send();
        }else{
            parserMAFData(filename, "no", parserMaf);
        }
    }
}


// maf延迟解析控制函数
function parserControl(AfterExec) {
    // window.setTimeout(function(){
    //     AfterExec();
    // },0)
    // //console.log("parserControl"+getTime());
}

// 解析单步的牙龈数据
function parseGingivaData(parserMaf, i) { // 牙龈数据
    var bGet = false;
    // 公用数据是否读取成功标志
    // var gingivaBuild = new window.Module.CGingivaBuild();
    var nGingivaID = 0;
    var ptVertex = new window.CVector(); // 公用点对象
    var ptFaceIndex = new window.CVector();
    // 公用面对象
    // gingivaBuild.BuildGingivaMesh(mafModel);
    // 公用牙龈编号
    var a = i;
    var vertexnorm = [];
    var vertexcolors = [];
    var vertexcolor = [];
    var geom = null;
    for (; i < a + 1; i++) {
        gingivaBuild.SetGingivaMeshOfStep(mafModel, i);

        // 上颌牙龈
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var upperGingiva = mafModel.GetUpperGingiva();
            var upperGingivaNum = upperGingiva.GetMeshVertex();
            var upperGingivaFaceNum = upperGingiva.GetMeshFace();

            // var upGingivaMesh = {};
            var upGingivaVertex = [];
            var upGingivaVertexNorm = [];
            var upGingivaFace = [];
            vertexcolors.length = 0;
            // 顶点
            for (var vi = 0; vi < upperGingivaNum; vi++) {
                bGet = upperGingiva.GetVertexXYZ(ptVertex, vi);
                if (bGet) {
                    upGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = upperGingiva.GetVertexNorm(ptVertex, vi);
                if (bGet) {
                    upGingivaVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }
            GingivaGradient(upGingivaVertex, true, vertexcolors, i);
            // console.log("牙龈点" + upperGingivaNum);
            // 面的顶点索引
            for (var fi = 0; fi < upperGingivaFaceNum; fi++) {
                bGet = upperGingiva.GetFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetX()]);
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetY()]);
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetZ()]);
                    vertexcolor = [];
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetX()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetY()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetZ()]);
                    upGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }
            upperGingiva = null;
            // console.log("牙龈面" + upperGingivaFaceNum);

            // upGingivaMesh = createGeometry(upGingivaVertex, upGingivaFace, gingivaMaterial,true);
            // scene.add(upGingivaMesh);
            // vecUpGingiva.set(nGingivaID, upGingivaMesh);

            // G_upperGingivaMesh .dispose();
            // G_upperGingivaMesh  = createGeometry(upGingivaVertex, upGingivaFace, gingivaMaterial,true);
            G_upperGingivaMesh.geometry.dispose();
            geom = new window.THREE.Geometry();
            geom.vertices = upGingivaVertex;
            geom.faces = upGingivaFace;
            G_upperGingivaMesh.geometry = new window.THREE.BufferGeometry().fromGeometry(geom);
            geom.dispose();
            geom = null;
            // G_upperGingivaMesh.geometry.computeFaceNormals();
            // G_upperGingivaMesh.geometry.computeVertexNormals();
            vertexnorm.length = 0;
            upGingivaVertex.length = 0;
            upGingivaVertexNorm.length = 0;
            upGingivaFace.length = 0;
            upGingivaVertex = null;
            upGingivaVertexNorm = null;
            upGingivaFace = null;
        }

        // 下颌牙龈
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var lowerGingiva = mafModel.GetLowerGingiva();
            var lowerGingivaNum = lowerGingiva.GetMeshVertex();
            var lowerGingivaFaceNum = lowerGingiva.GetMeshFace();

            // var lowGingivaMesh = {};
            var lowGingivaVertex = [];
            var lowGingivaVertexNorm = [];
            var lowGingivaFace = [];
            vertexcolors.length = 0;
            // 顶点
            for (vi = 0; vi < lowerGingivaNum; vi ++) {
                bGet = lowerGingiva.GetVertexXYZ(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = lowerGingiva.GetVertexNorm(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }
            GingivaGradient(lowGingivaVertex, false, vertexcolors, i);
            // 面的顶点索引
            for (fi = 0; fi < lowerGingivaFaceNum; fi ++) {
                bGet = lowerGingiva.GetFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetX()]);
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetY()]);
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetZ()]);
                    vertexcolor = [];
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetX()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetY()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetZ()]);
                    lowGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }

            // lowGingivaMesh = createGeometry(lowGingivaVertex, lowGingivaFace, gingivaMaterial,true);
            // // lowGingivaMesh.name = "lowGingivaMesh";//即时计算
            // scene.add(lowGingivaMesh);
            // vecLowGingiva.set(nGingivaID, lowGingivaMesh);
            lowerGingiva = null;

            // G_lowGingivaMesh.dispose();
            // G_lowGingivaMesh = createGeometry(lowGingivaVertex, lowGingivaFace, gingivaMaterial,true);


            G_lowGingivaMesh.geometry.dispose();
            geom = new window.THREE.Geometry();
            geom.vertices = lowGingivaVertex;
            geom.faces = lowGingivaFace;
            G_lowGingivaMesh.geometry = new window.THREE.BufferGeometry().fromGeometry(geom);
            geom.dispose();
            geom = null;
            // G_lowGingivaMesh.geometry.computeFaceNormals();
            // G_lowGingivaMesh.geometry.computeVertexNormals();
            vertexnorm.length = 0;
            lowGingivaVertex.length = 0;
            lowGingivaVertexNorm.length = 0;
            lowGingivaFace.length = 0;
            lowGingivaVertex = null;
            lowGingivaVertexNorm = null;
            lowGingivaFace = null;

            /*测试重复加载牙龈*/
            // var noSmoothMesh = createGeometry(lowGingivaVertex, lowGingivaFace, gingivaMaterial,false);
            // scene.add(noSmoothMesh);

            // 提前加载
            // vecLowGingiva.set("lowGingivaMesh", lowGingivaMesh);//即时计算
        }

        vertexcolor = [];
        vertexcolor.push(new window.THREE.Color("rgb(183, 90, 100)"));
        vertexcolor.push(new window.THREE.Color("rgb(183, 90, 100)"));
        vertexcolor.push(new window.THREE.Color("rgb(183, 90, 100)"));

        // 上颌牙龈底座
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var upperGingiva_Base = mafModel.GetUpperGingiva();
            var upperGingivaNum_Base = upperGingiva_Base.GetBaseMeshVertex();
            var upperGingivaFaceNum_Base = upperGingiva_Base.GetBaseMeshFace();

            // var upGingivaMesh_Base = {};
            var upGingivaVertex_Base = [];
            var upGingivaVertexNorm_Base = [];
            var upGingivaFace_Base = [];
            // 顶点
            for (vi = 0; vi < upperGingivaNum_Base; vi ++) {
                bGet = upperGingiva_Base.GetBaseVertexXYZ(ptVertex, vi);
                if (bGet) {
                    upGingivaVertex_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = upperGingiva_Base.GetBaseVertexNorm(ptVertex, vi);
                if (bGet) {
                    upGingivaVertexNorm_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }
            // console.log("牙龈点" + upperGingivaNum);
            // 面的顶点索引
            for (fi = 0; fi < upperGingivaFaceNum_Base; fi ++) {
                bGet = upperGingiva_Base.GetBaseFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetX()]);
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetY()]);
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetZ()]);
                    upGingivaFace_Base.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }
            // console.log("牙龈面" + upperGingivaFaceNum);

            // upGingivaMesh_Base = createGeometry(upGingivaVertex_Base, upGingivaFace_Base, gingivaMaterial,false);
            // // upGingivaMesh.name = "upGingivaMesh";//即时计算
            // scene.add(upGingivaMesh_Base);
            // vecUpGingiva_Base.set(nGingivaID, upGingivaMesh_Base);
            upperGingiva_Base = null;


            // G_upperGingivaMesh_Base.dispose();
            // G_upperGingivaMesh_Base  = createGeometry(upGingivaVertex_Base, upGingivaFace_Base, gingivaMaterial,false);


            G_upperGingivaMesh_Base.geometry.dispose();
            geom = new window.THREE.Geometry();
            geom.vertices = upGingivaVertex_Base;
            geom.faces = upGingivaFace_Base;
            G_upperGingivaMesh_Base.geometry = new window.THREE.BufferGeometry().fromGeometry(geom);
            geom.dispose();
            geom = null;
            //    G_upperGingivaMesh_Base.geometry.computeFaceNormals();
            //    G_upperGingivaMesh_Base.geometry.computeVertexNormals();
            vertexnorm.length = 0;
            upGingivaVertex_Base.length = 0;
            upGingivaVertexNorm_Base.length = 0;
            upGingivaFace_Base.length = 0;
            upGingivaVertex_Base = null;
            upGingivaVertexNorm_Base = null;
            upGingivaFace_Base = null;
            // 提前加载
            // vecUpGingiva.set("upGingivaMesh", upGingivaMesh);//即时计算
        }

        // 下颌牙龈底座
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var lowerGingiva_Base = mafModel.GetLowerGingiva();
            var lowerGingivaNum_Base = lowerGingiva_Base.GetBaseMeshVertex();
            var lowerGingivaFaceNum_Base = lowerGingiva_Base.GetBaseMeshFace();

            // var lowGingivaMesh_Base = {};
            var lowGingivaVertex_Base = [];
            var lowGingivaVertexNorm_Base = [];
            var lowGingivaFace_Base = [];
            // 顶点
            for (vi = 0; vi < lowerGingivaNum_Base; vi ++) {
                bGet = lowerGingiva_Base.GetBaseVertexXYZ(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertex_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = lowerGingiva_Base.GetBaseVertexNorm(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertexNorm_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }

            // 面的顶点索引
            for (fi = 0; fi < lowerGingivaFaceNum_Base; fi ++) {
                bGet = lowerGingiva_Base.GetBaseFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetX()]);
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetY()]);
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetZ()]);
                    lowGingivaFace_Base.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }

            // lowGingivaMesh_Base = createGeometry(lowGingivaVertex_Base, lowGingivaFace_Base, gingivaMaterial,false);

            // // lowGingivaMesh.name = "lowGingivaMesh";//即时计算
            // scene.add(lowGingivaMesh_Base);
            // vecLowGingiva_Base.set(nGingivaID, lowGingivaMesh_Base);
            lowerGingiva_Base = null;

            // G_lowGingivaMesh_Base.dispose();

            // G_lowGingivaMesh_Base  = createGeometry(lowGingivaVertex_Base, lowGingivaFace_Base, gingivaMaterial,false);


            G_lowGingivaMesh_Base.geometry.dispose();
            geom = new window.THREE.Geometry();
            geom.vertices = lowGingivaVertex_Base;
            geom.faces = lowGingivaFace_Base;
            G_lowGingivaMesh_Base.geometry = new window.THREE.BufferGeometry().fromGeometry(geom);
            geom.dispose();
            geom = null;
            // G_lowGingivaMesh_Base.geometry.computeFaceNormals();
            // G_lowGingivaMesh_Base.geometry.computeVertexNormals();
            vertexnorm.length = 0;
            lowGingivaVertex_Base.length = 0;
            lowGingivaVertexNorm_Base.length = 0;
            lowGingivaFace_Base.length = 0;
            lowGingivaVertex_Base = null;
            lowGingivaVertexNorm_Base = null;
            lowGingivaFace_Base = null;


            // 提前加载
            // vecLowGingiva.set("lowGingivaMesh", lowGingivaMesh);//即时计算
        }

    }

}


// 解析MAF文件
function parserMAFData(filename, b7z, parserMaf) {
    if (JSON.stringify(mafModel) == '{}'){
        mafModel = parserMaf.GetModel(); // 获取模型数据集
    }
    nTotalStep = mafModel.GetUpperJawTotalTreatmentStep(); // 总步数
    var pShell = {}; // 公用Shell对象
    var i = 0; // 公用计数器
    var bGet = false; // 公用数据是否读取成功标志
    var ptVertex = new window.CVector(); // 公用点对象
    var ptFaceIndex = new window.CVector();
    // 公用面对象

    // 牙龈数据
    var nGingivaID = 0;
    var vertexnorm = [];
    var vertexcolors = [];
    var vertexcolor = [];
    var vertexcurv = [];
    gingivaBuild.BuildGingivaMesh(mafModel);
    
    // 牙齿和附件数据
    var nShellListSize = mafModel.GetShellListSize();
    var nAttachListSize = mafModel.GetAttachListSize();
    // 获取牙齿&附件数据集
    // 附件数量
    // 位置信息
    for (i = 0; i < nShellListSize; i++) {
        pShell = mafModel.GetShell(i);
        let nToothID = pShell.GetToothFDI();
        let vecStepOffset = new Array(); // 单个模型的Location数据
        let vecStepQuaternion = new Array(); // 单个模型的Location数据
        for (let setpCount = 0; setpCount < nTotalStep; setpCount++) {
            let stepLocation = pShell.GetLocationOfStep(setpCount);
            let stepOffset = stepLocation.GetOffset();
            let stepQuaternion = stepLocation.GetQuaternion();

            vecStepOffset.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()));
            vecStepQuaternion.push(new window.THREE.Quaternion(stepQuaternion.GetX(), stepQuaternion.GetY(), stepQuaternion.GetZ(), stepQuaternion.GetW()));
        }
        mapStepinfo_Offset.set(nToothID, vecStepOffset);
        mapStepinfo_Quaternion.set(nToothID, vecStepQuaternion);
        pShell = null;
    }
    for (i = 0; i < nAttachListSize; i++) {
        pShell = mafModel.GetAttachment(i);
        let nParentID = pShell.GetParentFDI();
        if (nParentID == -1) continue;
        let nAttachID = nParentID * 1000 + i;
        let vecStepOffset = new Array(); // 单个模型的Location数据
        let vecStepQuaternion = new Array(); // 单个模型的Location数据
        for (let setpCount = 0; setpCount < nTotalStep; setpCount++) {
            if (pShell.IsAttachInRange(setpCount)){
                let stepLocation = pShell.GetAttachLocationOfStep(setpCount);
                let stepOffset = stepLocation.GetOffset();
                let stepQuaternion = stepLocation.GetQuaternion();
    
                vecStepOffset.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()));
                vecStepQuaternion.push(new window.THREE.Quaternion(stepQuaternion.GetX(), stepQuaternion.GetY(), stepQuaternion.GetZ(), stepQuaternion.GetW()));
            }else{
                vecStepOffset.push(new window.THREE.Vector3(0,0,0));
                vecStepQuaternion.push(new window.THREE.Quaternion(1,0,0,0));
            }
        }
        mapStepinfo_Offset.set(nAttachID, vecStepOffset);
        mapStepinfo_Quaternion.set(nAttachID, vecStepQuaternion);
        pShell = null;
    }

    // 模型数据
    for (i = 0; i < nShellListSize; i++) {
        pShell = mafModel.GetShell(i); // 单个Shell对象数据
        let nToothID = pShell.GetToothFDI();
        let offset = mapStepinfo_Offset.get(nToothID)[0]; // 偏移量
        let quat = mapStepinfo_Quaternion.get(nToothID)[0]; // 四元数
        
        let j = 0; // 临时公用点面计数器
        let nFaceNum = pShell.GetMeshFace();
        let nVertexNum = pShell.GetMeshVertex();
        let toothMesh = {};
        let toothVertex = [];
        let toothVertexNorm = [];
        let toothFace = [];
        vertexcolor.length = 0;
        vertexcolors.length = 0;
        vertexcurv.length = 0;
        for (j = 0; j < nVertexNum; j++) {
            bGet = pShell.GetVertexXYZ(ptVertex, j);
            if (bGet) {
                toothVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
            bGet = pShell.GetVertexNorm(ptVertex, j);
            if (bGet) {
                toothVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
            let curv = pShell.GetVertexCurv(j);
            vertexcurv.push(curv);
        }
        ToothGradient(toothVertex, toothVertexNorm, vertexcurv, vertexcolors);
        for (j = 0; j < nFaceNum; j++) {
            bGet = pShell.GetFaceIndex(ptFaceIndex, j);
            if (bGet) {
                vertexnorm = [];
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetX()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetY()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetZ()]);
                vertexcolor = [];
                vertexcolor.push(vertexcolors[ptFaceIndex.GetX()]);
                vertexcolor.push(vertexcolors[ptFaceIndex.GetY()]);
                vertexcolor.push(vertexcolors[ptFaceIndex.GetZ()]);
                toothFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
            }
        }
        toothMesh = createGeometry(toothVertex, toothFace, toothMaterial, false); // 初始坐标在(0,0,0),四元数为(1.0.0.0)
        toothMesh.position.set(offset.x, offset.y, offset.z); // 设定偏移量
        toothMesh.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
        scene.add(toothMesh); // 把当前模型置入场景
        vertexnorm.length = 0;
        toothVertex.length = 0;
        toothVertexNorm.length = 0;
        toothFace.length = 0;
        toothVertex = null;
        toothVertexNorm = null;
        toothFace = null;
        vecTeeth.set(nToothID, toothMesh); // 设定模型键值对索引关系
        pShell = null;
    }
    for (i = 0; i < nAttachListSize; i++) {
        pShell = mafModel.GetAttachment(i); // 单个Shell对象数据
        let nParentID = pShell.GetParentFDI();
        if (nParentID == -1) continue;
        let nAttachID = nParentID * 1000 + i; // 牙齿ID+容器内序列

        let j = 0; // 临时公用点面计数器
        let nFaceNum = pShell.GetMeshFace();
        let nVertexNum = pShell.GetMeshVertex();
        let toothMesh = {};
        let toothVertex = [];
        let toothVertexNorm = [];
        let toothFace = [];
        for (j = 0; j < nVertexNum; j++) {
            bGet = pShell.GetVertexXYZ(ptVertex, j);
            if (bGet) {
                toothVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
            bGet = pShell.GetVertexNorm(ptVertex, j);
            if (bGet) {
                toothVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
        }

        for (j = 0; j < nFaceNum; j++) {
            bGet = pShell.GetFaceIndex(ptFaceIndex, j);
            if (bGet) {
                vertexnorm = [];
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetX()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetY()]);
                vertexnorm.push(toothVertexNorm[ptFaceIndex.GetZ()]);
                toothFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm));
            }
        }
        toothMesh = createGeometry(toothVertex, toothFace, attachMaterial, false); // 初始坐标在(0,0,0),四元数为(1.0.0.0)
        toothMesh.visible = pShell.IsAttachInRange(0);
        if (toothMesh.visible){
            let offset = mapStepinfo_Offset.get(nAttachID)[0]; // 偏移量
            let quat = mapStepinfo_Quaternion.get(nAttachID)[0]; // 四元数
            toothMesh.position.set(offset.x, offset.y, offset.z); // 设定偏移量
            toothMesh.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
        }
        scene.add(toothMesh); // 把当前模型置入场景
        vertexnorm.length = 0;
        toothVertex.length = 0;
        toothVertexNorm.length = 0;
        toothFace.length = 0;
        toothVertex = null;
        toothVertexNorm = null;
        toothFace = null;
        vecAttachment.set(nAttachID, toothMesh); // 设定模型键值对索引关系
        pShell = null;
    }

    // 公用牙龈编号
    for (i = 0; i < 1; i++) { // nTotalStep  1
        gingivaBuild.SetGingivaMeshOfStep(mafModel, i);

        // 上颌牙龈
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var upperGingiva = mafModel.GetUpperGingiva();
            var upperGingivaNum = upperGingiva.GetMeshVertex();
            var upperGingivaFaceNum = upperGingiva.GetMeshFace();

            // var upGingivaMesh = {};
            var upGingivaVertex = [];
            var upGingivaVertexNorm = [];
            var upGingivaFace = [];
            vertexcolors.length = 0;
            // 顶点
            for (var vi = 0; vi < upperGingivaNum; vi++) {
                bGet = upperGingiva.GetVertexXYZ(ptVertex, vi);
                if (bGet) {
                    upGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = upperGingiva.GetVertexNorm(ptVertex, vi);
                if (bGet) {
                    upGingivaVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }
            GingivaGradient(upGingivaVertex, true, vertexcolors);
            // console.log("牙龈点" + upperGingivaNum);
            // 面的顶点索引
            for (var fi = 0; fi < upperGingivaFaceNum; fi++) {
                bGet = upperGingiva.GetFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetX()]);
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetY()]);
                    vertexnorm.push(upGingivaVertexNorm[ptFaceIndex.GetZ()]);
                    vertexcolor = [];
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetX()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetY()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetZ()]);
                    upGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }
            // console.log("牙龈面" + upperGingivaFaceNum);
            upperGingiva = null;
            // upGingivaMesh = createGeometry(upGingivaVertex, upGingivaFace, gingivaMaterial,true);
            // scene.add(upGingivaMesh);
            // vecUpGingiva.set(nGingivaID, upGingivaMesh);

            G_upperGingivaMesh = createGeometry(upGingivaVertex, upGingivaFace, gingivaMaterial, true);
            scene.add(G_upperGingivaMesh);
            vertexnorm.length = 0;
            upGingivaVertex.length = 0;
            upGingivaFace.length = 0;
            upGingivaVertexNorm.length = 0;
            upGingivaVertex = null;
            upGingivaVertexNorm = null;
            upGingivaFace = null;
        }

        // 下颌牙龈
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var lowerGingiva = mafModel.GetLowerGingiva();
            var lowerGingivaNum = lowerGingiva.GetMeshVertex();
            var lowerGingivaFaceNum = lowerGingiva.GetMeshFace();

            // var lowGingivaMesh = {};
            var lowGingivaVertex = [];
            var lowGingivaVertexNorm = [];
            var lowGingivaFace = [];
            vertexcolors.length = 0;
            // 顶点
            for (vi = 0; vi < lowerGingivaNum; vi ++) {
                bGet = lowerGingiva.GetVertexXYZ(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = lowerGingiva.GetVertexNorm(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertexNorm.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }
            GingivaGradient(lowGingivaVertex, false, vertexcolors);
            // 面的顶点索引
            for (fi = 0; fi < lowerGingivaFaceNum; fi ++) {
                bGet = lowerGingiva.GetFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetX()]);
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetY()]);
                    vertexnorm.push(lowGingivaVertexNorm[ptFaceIndex.GetZ()]);
                    vertexcolor = [];
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetX()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetY()]);
                    vertexcolor.push(vertexcolors[ptFaceIndex.GetZ()]);
                    lowGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }

            // lowGingivaMesh = createGeometry(lowGingivaVertex, lowGingivaFace, gingivaMaterial,true);
            // // lowGingivaMesh.name = "lowGingivaMesh";//即时计算
            // scene.add(lowGingivaMesh);
            // vecLowGingiva.set(nGingivaID, lowGingivaMesh);
            lowerGingiva = null;
            G_lowGingivaMesh = createGeometry(lowGingivaVertex, lowGingivaFace, gingivaMaterial, true);
            // lowGingivaMesh.name = "lowGingivaMesh";//即时计算
            scene.add(G_lowGingivaMesh);
            vertexnorm.length = 0;
            lowGingivaVertex.length = 0;
            lowGingivaVertexNorm.length = 0;
            lowGingivaFace.length = 0;
            lowGingivaVertex = null;
            lowGingivaVertexNorm = null;
            lowGingivaFace = null;

            /*测试重复加载牙龈*/
            // var noSmoothMesh = createGeometry(lowGingivaVertex, lowGingivaFace, gingivaMaterial,false);
            // scene.add(noSmoothMesh);

            // 提前加载
            // vecLowGingiva.set("lowGingivaMesh", lowGingivaMesh);//即时计算
        }

        vertexcolor = [];
        vertexcolor.push(new window.THREE.Color("rgb(183, 90, 100)"));
        vertexcolor.push(new window.THREE.Color("rgb(183, 90, 100)"));
        vertexcolor.push(new window.THREE.Color("rgb(183, 90, 100)"));

        // 上颌牙龈底座
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var upperGingiva_Base = mafModel.GetUpperGingiva();
            var upperGingivaNum_Base = upperGingiva_Base.GetBaseMeshVertex();
            var upperGingivaFaceNum_Base = upperGingiva_Base.GetBaseMeshFace();

            // var upGingivaMesh_Base = {};
            var upGingivaVertex_Base = [];
            var upGingivaVertexNorm_Base = [];
            var upGingivaFace_Base = [];
            // 顶点
            for (vi = 0; vi < upperGingivaNum_Base; vi ++) {
                bGet = upperGingiva_Base.GetBaseVertexXYZ(ptVertex, vi);
                if (bGet) {
                    upGingivaVertex_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = upperGingiva_Base.GetBaseVertexNorm(ptVertex, vi);
                if (bGet) {
                    upGingivaVertexNorm_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }
            // console.log("牙龈点" + upperGingivaNum);
            // 面的顶点索引
            for (fi = 0; fi < upperGingivaFaceNum_Base; fi ++) {
                bGet = upperGingiva_Base.GetBaseFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetX()]);
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetY()]);
                    vertexnorm.push(upGingivaVertexNorm_Base[ptFaceIndex.GetZ()]);
                    upGingivaFace_Base.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }
            // console.log("牙龈面" + upperGingivaFaceNum);

            // upGingivaMesh_Base = createGeometry(upGingivaVertex_Base, upGingivaFace_Base, gingivaMaterial,false);
            // // upGingivaMesh.name = "upGingivaMesh";//即时计算
            // scene.add(upGingivaMesh_Base);
            // vecUpGingiva_Base.set(nGingivaID, upGingivaMesh_Base);
            upperGingiva_Base = null;

            G_upperGingivaMesh_Base = createGeometry(upGingivaVertex_Base, upGingivaFace_Base, gingivaMaterial, false);
            // upGingivaMesh.name = "upGingivaMesh";//即时计算
            scene.add(G_upperGingivaMesh_Base);
            // vecUpGingiva_Base.set(nGingivaID, upGingivaMesh_Base);
            vertexnorm.length = 0;
            upGingivaVertex_Base.length = 0;
            upGingivaVertexNorm_Base.length = 0;
            upGingivaFace_Base.length = 0;
            upGingivaVertex_Base = null;
            upGingivaVertexNorm_Base = null;
            upGingivaFace_Base = null;

            // 提前加载
            // vecUpGingiva.set("upGingivaMesh", upGingivaMesh);//即时计算
        }

        // 下颌牙龈底座
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var lowerGingiva_Base = mafModel.GetLowerGingiva();
            var lowerGingivaNum_Base = lowerGingiva_Base.GetBaseMeshVertex();
            var lowerGingivaFaceNum_Base = lowerGingiva_Base.GetBaseMeshFace();

            // var lowGingivaMesh_Base = {};
            var lowGingivaVertex_Base = [];
            var lowGingivaVertexNorm_Base = [];
            var lowGingivaFace_Base = [];
            // 顶点
            for (vi = 0; vi < lowerGingivaNum_Base; vi ++) {
                bGet = lowerGingiva_Base.GetBaseVertexXYZ(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertex_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
                bGet = lowerGingiva_Base.GetBaseVertexNorm(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertexNorm_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }

            // 面的顶点索引
            for (fi = 0; fi < lowerGingivaFaceNum_Base; fi ++) {
                bGet = lowerGingiva_Base.GetBaseFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    vertexnorm = [];
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetX()]);
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetY()]);
                    vertexnorm.push(lowGingivaVertexNorm_Base[ptFaceIndex.GetZ()]);
                    lowGingivaFace_Base.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ(), vertexnorm, vertexcolor));
                }
            }

            // lowGingivaMesh_Base = createGeometry(lowGingivaVertex_Base, lowGingivaFace_Base, gingivaMaterial,false);
            // // lowGingivaMesh.name = "lowGingivaMesh";//即时计算
            // scene.add(lowGingivaMesh_Base);
            // vecLowGingiva_Base.set(nGingivaID, lowGingivaMesh_Base);
            lowerGingiva_Base = null;


            G_lowGingivaMesh_Base = createGeometry(lowGingivaVertex_Base, lowGingivaFace_Base, gingivaMaterial, false);
            scene.add(G_lowGingivaMesh_Base);
            vertexnorm.length = 0;
            lowGingivaVertex_Base.length = 0;
            lowGingivaVertexNorm_Base.length = 0;
            lowGingivaFace_Base.length = 0;
            lowGingivaVertex_Base = null;
            lowGingivaVertexNorm_Base = null;
            lowGingivaFace_Base = null;
            // 提前加载
            // vecLowGingiva.set("lowGingivaMesh", lowGingivaMesh);//即时计算
        }

    }

    // var vBoxCenter = new window.CVector();
    // vBoxCenter = mafModel.GetModelBoxCenter();
    // ptBoxCenter.x = vBoxCenter.GetX();
    // ptBoxCenter.y = vBoxCenter.GetY();
    // ptBoxCenter.z = vBoxCenter.GetZ();

    // controls.position0.x = vBoxCenter.GetX();
    // controls.position0.y = vBoxCenter.GetY() - 10;
    // controls.position0.z = vBoxCenter.GetZ();

    // controls.target0.set(ptBoxCenter.x, ptBoxCenter.y, ptBoxCenter.z);
    // controls.up0.set(0, 0, 1); // set a new up vector
    // controls.reset();

    controls.staticMoving = true;
    // 关闭惯性
    // scene.add(createTestMesh(controls.target0.x,controls.target0.y,controls.target0.z,cutterMaterial));

    stepBar = [];
    // 步数传出
    pShell = mafModel.GetShell(0);
    for (i = 0; i < nTotalStep; i++) {
        let type = [];
        if (pShell.IsStepNOProduction(i)){
            type.push(1);
        }else{
            type.push(0);
        }
        stepBar.push(type);
    }
    pShell = mafModel.GetShell(nShellListSize-1);
    for (i = 0; i < nTotalStep; i++) {
        if (pShell.IsStepNOProduction(i)){
            stepBar[i].push(1);
        }else{
            stepBar[i].push(0);
        }
    }
    pShell = null;
    console.log("方案步数"+i);
    localStorage.setItem(caseno, JSON.stringify(stepBar));

    // 开窗挂钩数据
    {
        // 挂钩位置信息
        // var nHookListSize = parserMaf.GetHookAmount();
        // for (i = 0; i < nHookListSize; i++) {
        //     pShell = parserMaf.GetHook(i);
        //     var nHookID = pShell.GetParentFDI() * 1000 + i + nShellListSize;
        //     vecStepOffset = new Array(); // 单个模型的Location数据
        //     vecStepQuaternion = new Array(); // 单个模型的Location数据
        //     for (setpCount = 0; setpCount < nTotalStep; setpCount ++) {
        //         stepLocation = pShell.GetAttachLocationOfStep(setpCount);
        //         stepOffset = stepLocation.GetOffset();
        //         stepQuaternion = stepLocation.GetQuaternion();

        //         vecStepOffset.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()));
        //         vecStepQuaternion.push(new window.THREE.Quaternion(stepQuaternion.GetX(), stepQuaternion.GetY(), stepQuaternion.GetZ(), stepQuaternion.GetW()));
        //     }
        //     mapStepinfo_Offset.set(nHookID, vecStepOffset);
        //     mapStepinfo_Quaternion.set(nHookID, vecStepQuaternion);
        // }

        // 开窗位置信息--扣子
        // var nWindowListSize = parserMaf.GetWindowAmount();
        // for (i = 0; i < nWindowListSize; i++) {
        //     pShell = parserMaf.GetWindow(i);
        //     var nWindowID = pShell.GetParentFDI() * 1000 + i + nShellListSize + nHookListSize;
        //     vecStepOffset = new Array(); // 单个模型的Location数据
        //     vecStepQuaternion = new Array(); // 单个模型的Location数据
        //     for (setpCount = 0; setpCount < nTotalStep; setpCount ++) {
        //         stepLocation = pShell.GetAttachLocationOfStep(setpCount);
        //         stepOffset = stepLocation.GetOffset();
        //         stepQuaternion = stepLocation.GetQuaternion();

        //         vecStepOffset.push(new window.THREE.Vector3(stepOffset.GetX(), stepOffset.GetY(), stepOffset.GetZ()));
        //         vecStepQuaternion.push(new window.THREE.Quaternion(stepQuaternion.GetX(), stepQuaternion.GetY(), stepQuaternion.GetZ(), stepQuaternion.GetW()));
        //     }
        //     mapStepinfo_Offset.set(nWindowID, vecStepOffset);
        //     mapStepinfo_Quaternion.set(nWindowID, vecStepQuaternion);
        // }

        // 挂钩模型数据
        // for (i = 0; i < nHookListSize; i++) {
        //     pShell = parserMaf.GetHook(i); // 单个Shell对象数据
        //     loc = pShell.GetAttachLocationOfStep(0); // 位置信息
        //     offset = loc.GetOffset(); // 偏移量
        //     quat = loc.GetQuaternion(); // 四元数

        //     j = 0; // 临时公用点面计数器
        //     nFaceNum = pShell.GetMeshFace();
        //     nVertexNum = pShell.GetMeshVertex();
        //     toothMesh = {};
        //     toothVertex = [];
        //     toothFace = [];
        //     for (j = 0; j < nVertexNum; j ++) {
        //         bGet = pShell.GetVertexXYZ(ptVertex, j);
        //         if (bGet) {
        //             toothVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
        //         }
        //     }

        //     for (j = 0; j < nFaceNum; j ++) {
        //         bGet = pShell.GetFaceIndex(ptFaceIndex, j);
        //         if (bGet) {
        //             toothFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ()));
        //         }
        //     }
        //     toothMesh = createGeometry(toothVertex, toothFace, cutterMaterial); // 初始坐标在(0,0,0),四元数为(1.0.0.0)

        //     toothMesh.position.set(offset.GetX(), offset.GetY(), offset.GetZ()); // 设定偏移量
        //     toothMesh.quaternion.set(quat.GetX(), quat.GetY(), quat.GetZ(), quat.GetW()); // 设定四元数旋转量
        //     if (offset.x == 0 && offset.y == 0 && offset.z == 0 && pShell.IsAttache()) { // 若在这一步不应该加上此附件
        //         toothMesh.visible = false; // 不显示
        //     }scene.add(toothMesh); // 把当前模型置入场景

        //     nAttachID = pShell.GetParentFDI() * 1000 + i + nShellListSize; // 牙齿ID+容器内序列
        //     vecAttachment.set(nAttachID, toothMesh); // 设定模型键值对索引关系
        // }

        // 开窗模型数据--扣子
        // for (i = 0; i < nWindowListSize; i++) {
        //     pShell = parserMaf.GetWindow(i); // 单个Shell对象数据
        //     loc = pShell.GetAttachLocationOfStep(0); // 位置信息
        //     offset = loc.GetOffset(); // 偏移量
        //     quat = loc.GetQuaternion(); // 四元数

        //     j = 0; // 临时公用点面计数器
        //     nFaceNum = pShell.GetMeshFace();
        //     nVertexNum = pShell.GetMeshVertex();
        //     toothMesh = {};
        //     toothVertex = [];
        //     toothFace = [];
        //     for (j = 0; j < nVertexNum; j ++) {
        //         bGet = pShell.GetVertexXYZ(ptVertex, j);
        //         if (bGet) {
        //             toothVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
        //         }
        //     }

        //     for (j = 0; j < nFaceNum; j ++) {
        //         bGet = pShell.GetFaceIndex(ptFaceIndex, j);
        //         if (bGet) {
        //             toothFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ()));
        //         }
        //     }

        //     toothMesh = createGeometry(toothVertex, toothFace, cutterMaterial); // 初始坐标在(0,0,0),四元数为(1.0.0.0)

        //     toothMesh.position.set(offset.GetX(), offset.GetY(), offset.GetZ()); // 设定偏移量
        //     toothMesh.quaternion.set(quat.GetX(), quat.GetY(), quat.GetZ(), quat.GetW()); // 设定四元数旋转量
        //     if (offset.x == 0 && offset.y == 0 && offset.z == 0 && pShell.IsAttache()) { // 若在这一步不应该加上此附件
        //         toothMesh.visible = false; // 不显示
        //     }scene.add(toothMesh); // 把当前模型置入场景

        //     nAttachID = pShell.GetParentFDI() * 1000 + i + nShellListSize + nHookListSize; // 牙齿ID+容器内序列
        //     vecAttachment.set(nAttachID, toothMesh); // 设定模型键值对索引关系
        // }
    }

    pointModel(3);
    // parserControl(AfterExec);
    // console.log("ParserEnd"+getTime());
}

// maf延迟解析函数
function AfterExec() {
    if (JSON.stringify(mafModel) == '{}'){
        mafModel = parserMaf.GetModel(); // 获取模型数据集
    }
    nTotalStep = mafModel.GetUpperJawTotalTreatmentStep(); // 总步数
    var i = 0; // 公用计数器
    var bGet = false; // 公用数据是否读取成功标志
    var ptVertex = new window.CVector(); // 公用点对象
    var ptFaceIndex = new window.CVector();
    var gingivaBuild = new window.Module.CGingivaBuild();
    var nGingivaID = 0;
    for (i = 1; i < nTotalStep; i++) {
        gingivaBuild.BuildGingivaMesh(mafModel, i);

        // 上颌牙龈
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var upperGingiva = mafModel.GetUpperGingiva();
            var upperGingivaNum = upperGingiva.GetMeshVertex();
            var upperGingivaFaceNum = upperGingiva.GetMeshFace();

            var upGingivaMesh = {};
            var upGingivaVertex = [];
            var upGingivaFace = [];
            // 顶点
            for (var vi = 0; vi < upperGingivaNum; vi++) {
                bGet = upperGingiva.GetVertexXYZ(ptVertex, vi);
                if (bGet) {
                    upGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }
            // 面的顶点索引
            for (var fi = 0; fi < upperGingivaFaceNum; fi++) {
                bGet = upperGingiva.GetFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    upGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ()));
                }
            }

            upGingivaMesh = createGeometry(upGingivaVertex, upGingivaFace, gingivaMaterial, true);
            // upGingivaMesh.name = "upGingivaMesh";
            scene.add(upGingivaMesh);
            vecUpGingiva.set(nGingivaID, upGingivaMesh);
        }


        // 下颌牙龈
        {
            nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

            var lowerGingiva = mafModel.GetLowerGingiva();
            var lowerGingivaNum = lowerGingiva.GetMeshVertex();
            var lowerGingivaFaceNum = lowerGingiva.GetMeshFace();

            var lowGingivaMesh = {};
            var lowGingivaVertex = [];
            var lowGingivaFace = [];
            // 顶点
            for (vi = 0; vi < lowerGingivaNum; vi ++) {
                bGet = lowerGingiva.GetVertexXYZ(ptVertex, vi);
                if (bGet) {
                    lowGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
                }
            }

            // 面的顶点索引
            for (fi = 0; fi < lowerGingivaFaceNum; fi ++) {
                bGet = lowerGingiva.GetFaceIndex(ptFaceIndex, fi);
                if (bGet) {
                    lowGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ()));
                }
            }

            lowGingivaMesh = createGeometry(lowGingivaVertex, lowGingivaFace, gingivaMaterial, true);
            // lowGingivaMesh.name = "lowGingivaMesh";
            scene.add(lowGingivaMesh);
            vecLowGingiva.set(nGingivaID, lowGingivaMesh);
        }
    }
    playModel(0);
}
// 传入点集,面集,材质以创建集合体
function createGeometry(cm_vert, cm_face, material, bSubdivide) {
    var geom = new window.THREE.Geometry();
    // 将顶点和面赋给几何体
    geom.vertices = cm_vert;
    geom.faces = cm_face;
    // 计算每个面的法向量
    // geom.computeFaceNormals();
    // geom.computeVertexNormals();

    var buffergeom = new window.THREE.BufferGeometry().fromGeometry(geom);
    geom.dispose();
    geom = null;
    // var meshMaterial = material;
    // console.log("细分前"+geom.faces.length);
    var mesh = new window.THREE.Mesh(buffergeom, material);
    //if (bSubdivide == true) {
        // subdivide(mesh,1);
        // mesh.geometry.computeFaceNormals();
        // mesh.geometry.computeVertexNormals();
        // mesh.material.shading = window.THREE.SmoothShading;
        // mesh.material.shading = window.THREE.FlatShading;
    //}
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    mesh.updateMatrix();
    return mesh;
}
// 生成第n步的牙龈模型,并存入容器中
function createGingiva(step) {
    if (stepBar.length == 0) {
        return;
    }
    var i = step;
    var bGet = false; // 公用数据是否读取成功标志
    var ptVertex = new window.CVector(); // 公用点对象
    var ptFaceIndex = new window.CVector(); // 公用面对象
    var nGingivaID = 0; // 公用牙龈编号

    var gingivaBuild = new window.Module.CGingivaBuild();
    var bBuild = gingivaBuild.BuildGingivaMesh(mafModel, i);
    // var replaceObj = {};
    // 公用被替换obj

    // 上颌牙龈底座
    {
        nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

        var upperGingiva_Base = mafModel.GetUpperGingiva();
        var upperGingivaNum_Base = upperGingiva_Base.GetBaseMeshVertex();
        var upperGingivaFaceNum_Base = upperGingiva_Base.GetBaseMeshFace();

        var upGingivaMesh_Base = {};
        var upGingivaVertex_Base = [];
        var upGingivaFace_Base = [];
        // 顶点
        for (var vi = 0; vi < upperGingivaNum_Base; vi++) {
            bGet = upperGingiva_Base.GetBaseVertexXYZ(ptVertex, vi);
            if (bGet) {
                upGingivaVertex_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
        }
        // console.log("牙龈点" + upperGingivaNum);
        // 面的顶点索引
        for (var fi = 0; fi < upperGingivaFaceNum_Base; fi++) {
            bGet = upperGingiva_Base.GetBaseFaceIndex(ptFaceIndex, fi);
            if (bGet) {
                upGingivaFace_Base.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ()));
            }
        }
        // console.log("牙龈面" + upperGingivaFaceNum);

        upGingivaMesh_Base = createGeometry(upGingivaVertex_Base, upGingivaFace_Base, gingivaMaterial, false);
        // upGingivaMesh.name = "upGingivaMesh";//即时计算
        scene.add(upGingivaMesh_Base);
        vecUpGingiva_Base.set(step, upGingivaMesh_Base);
        // 提前加载
        // vecUpGingiva.set("upGingivaMesh", upGingivaMesh);//即时计算
    }

    // 下颌牙龈底座
    {
        nGingivaID = i; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

        var lowerGingiva_Base = mafModel.GetLowerGingiva();
        var lowerGingivaNum_Base = lowerGingiva_Base.GetBaseMeshVertex();
        var lowerGingivaFaceNum_Base = lowerGingiva_Base.GetBaseMeshFace();

        var lowGingivaMesh_Base = {};
        var lowGingivaVertex_Base = [];
        var lowGingivaFace_Base = [];
        // 顶点
        for (vi = 0; vi < lowerGingivaNum_Base; vi ++) {
            bGet = lowerGingiva_Base.GetBaseVertexXYZ(ptVertex, vi);
            if (bGet) {
                lowGingivaVertex_Base.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
        }

        // 面的顶点索引
        for (fi = 0; fi < lowerGingivaFaceNum_Base; fi ++) {
            bGet = lowerGingiva_Base.GetBaseFaceIndex(ptFaceIndex, fi);
            if (bGet) {
                lowGingivaFace_Base.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ()));
            }
        }

        lowGingivaMesh_Base = createGeometry(lowGingivaVertex_Base, lowGingivaFace_Base, gingivaMaterial, false);
        // lowGingivaMesh.name = "lowGingivaMesh";//即时计算
        scene.add(lowGingivaMesh_Base);
        vecLowGingiva_Base.set(step, lowGingivaMesh_Base);
        // 提前加载
        // vecLowGingiva.set("lowGingivaMesh", lowGingivaMesh);//即时计算
    }


    // 上颌牙龈
    {
        nGingivaID = i * 10 + 1; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

        var upperGingiva = mafModel.GetUpperGingiva();
        var upperGingivaNum = upperGingiva.GetMeshVertex();
        var upperGingivaFaceNum = upperGingiva.GetMeshFace();

        var upGingivaMesh = {};
        var upGingivaVertex = [];
        var upGingivaFace = [];
        // 顶点
        for (i = 0; i < upperGingivaNum; i++) {
            bGet = upperGingiva.GetVertexXYZ(ptVertex, i);
            if (bGet) {
                upGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
        }

        // 面的顶点索引
        for (i = 0; i < upperGingivaFaceNum; i++) {
            bGet = upperGingiva.GetFaceIndex(ptFaceIndex, i);
            if (bGet) {
                upGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ()));
            }
        }

        upGingivaMesh = createGeometry(upGingivaVertex, upGingivaFace, gingivaMaterial, true);
        upGingivaMesh.name = "upGingivaMesh";
        if (jawDisplayState != 1 && jawDisplayState != 2) {
            upGingivaMesh.visible = false;
        }
        // upGingivaMesh_Base.updateMatrix();
        // upGingivaMesh.updateMatrix();
        // upGingivaMesh.geometry.merge(upGingivaMesh_Base.geometry,upGingivaMesh_Base.matrix);
        // upGingivaMesh.geometry.merge(upGingivaMesh.geometry,upGingivaMesh.matrix);
        // upGingivaMesh.geometry.merge(new window.THREE.Geometry().fromBufferGeometry( upGingivaMesh_Base.geometry ), upGingivaMesh_Base.matrix);//模型合并
        scene.add(upGingivaMesh);
        vecUpGingiva.set(step, upGingivaMesh);
    }

    // 下颌牙龈
    {
        nGingivaID = i * 10 + 0; // 牙龈编号:步骤数+上下颌标识,1为上颌,0为下颌

        var lowerGingiva = mafModel.GetLowerGingiva();
        var lowerGingivaNum = lowerGingiva.GetMeshVertex();
        var lowerGingivaFaceNum = lowerGingiva.GetMeshFace();

        var lowGingivaMesh = {};
        var lowGingivaVertex = [];
        var lowGingivaFace = [];
        // 顶点
        for (i = 0; i < lowerGingivaNum; i++) {
            bGet = lowerGingiva.GetVertexXYZ(ptVertex, i);
            if (bGet) {
                lowGingivaVertex.push(new window.THREE.Vector3(ptVertex.GetX(), ptVertex.GetY(), ptVertex.GetZ()));
            }
        }

        // 面的顶点索引
        for (i = 0; i < lowerGingivaFaceNum; i++) {
            bGet = lowerGingiva.GetFaceIndex(ptFaceIndex, i);
            if (bGet) {
                lowGingivaFace.push(new window.THREE.Face3(ptFaceIndex.GetX(), ptFaceIndex.GetY(), ptFaceIndex.GetZ()));
            }
        }

        lowGingivaMesh = createGeometry(lowGingivaVertex, lowGingivaFace, gingivaMaterial, true);
        lowGingivaMesh.name = "lowGingivaMesh";
        if (jawDisplayState != -1 && jawDisplayState != 2) {
            lowGingivaMesh.visible = false;
        }
        // lowGingivaMesh_Base.updateMatrix();
        // lowGingivaMesh.geometry.merge(lowGingivaMesh_Base.geometry,lowGingivaMesh_Base.matrix);
        // lowGingivaMesh.geometry.merge(new window.THREE.Geometry().fromBufferGeometry( lowGingivaMesh_Base.geometry ), lowGingivaMesh_Base.matrix);//模型合并
        scene.add(lowGingivaMesh);
        vecLowGingiva.set(step, lowGingivaMesh);
    }
}
// 更新牙齿的位置信息
function playTeeth(step) {
    vecTeeth.forEach(function (value, key) {
        var offset = mapStepinfo_Offset.get(key)[step]; // 偏移量
        var quat = mapStepinfo_Quaternion.get(key)[step]; // 四元数
        value.position.set(offset.x, offset.y, offset.z); // 设定偏移量
        value.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量

        if (key > 10 && key < 30 && (jawDisplayState == 1 || jawDisplayState == 2)) {
            return;
        } else if (key > 30 && key < 50 && (jawDisplayState == -1 || jawDisplayState == 2)) {
            return;
        } else {
            value.visible = false;
        }
    });
    // 牙根
    vecTeeth_Root.forEach(function (value, key) {
        var offset = mapStepinfo_Offset.get(key)[step]; // 偏移量
        var quat = mapStepinfo_Quaternion.get(key)[step]; // 四元数
        vecTeeth_Root.get(key).position.set(offset.x, offset.y, offset.z); // 设定偏移量
        vecTeeth_Root.get(key).quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量

        if (key > 10 && key < 30 && (jawDisplayState == 1 || jawDisplayState == 2)) {
            return;
        } else if (key > 30 && key < 50 && (jawDisplayState == -1 || jawDisplayState == 2)) {
            return;
        } else {
            value.visible = false;
        }
    });
}
// 更新附件的位置信息
function playAttachment(step) {
    vecAttachment.forEach(function (value, key) {
        var offset = mapStepinfo_Offset.get(key)[step]; // 偏移量
        var quat = mapStepinfo_Quaternion.get(key)[step]; // 四元数
        if (offset.x == 0 && offset.y == 0 && offset.z == 0) { // 若在这一步不应该加上此附件
            value.visible = false; // 不显示
        } else {
            value.position.set(offset.x, offset.y, offset.z); // 设定偏移量
            value.quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
            var nParentID = key / 1000;
            if (nParentID > 10 && nParentID < 30 && (jawDisplayState === 1 || jawDisplayState === 2)) {
                value.visible = true;
            } else if (nParentID > 30 && nParentID < 50 && (jawDisplayState === -1 || jawDisplayState === 2)) {
                value.visible = true;
            } else {
                value.visible = false;
            }
        }
    });
}
// 显示双颌模型
function showDoubleJaw() {
    showMeshSet(vecTeeth, true);
    showMeshSet(vecTeeth_Root, true);
    showMeshSet(vecAttachment, true);
    shiftGingivaVisible(stepIndex, 2);
}
// 显示单颌模型
function showSingleJaw(bUpper) {
    if (bUpper == true) {
        showShell(vecTeeth, true);
        showShell(vecTeeth_Root, true);
        showShell(vecAttachment, true);
        shiftGingivaVisible(stepIndex, 1);
    } else {
        showShell(vecTeeth, false);
        showShell(vecTeeth_Root, false);
        showShell(vecAttachment, false);
        shiftGingivaVisible(stepIndex, -1);
    }
}
// 令模型容器内所有元素显示/隐藏
function showMeshSet(vecMesh, bVisible) {
    vecMesh.forEach(function (value, key) {
        value.visible = bVisible;
    });
}
// 令模型容器内所有上颌/下颌元素显示/隐藏
function showShell(vecMesh, bUpper) {
    vecMesh.forEach(function (value, key) {
        if (key > 10000) { // 是附件
            var nParentID = key / 1000;
            if (nParentID > 10 && nParentID < 30) { // 上颌
                if (bUpper) {
                    value.visible = true;
                } else {
                    value.visible = false;
                }
            } else if (nParentID > 30 && nParentID < 50) { // 下颌
                if (bUpper == false) {
                    value.visible = true;
                } else {
                    value.visible = false;
                }
            }
        } else { // 牙齿
            var nToothID = key;
            if (nToothID > 10 && nToothID < 30) { // 上颌
                if (bUpper) {
                    value.visible = true;
                } else {
                    value.visible = false;
                }
            } else if (nToothID > 30 && nToothID < 50) { // 下颌
                if (bUpper == false) {
                    value.visible = true;
                } else {
                    value.visible = false;
                }
            }
        }
    });
}

// 新版本切换第n步牙龈的显示
function shiftGingivaVisible(step, nJawState) {


    parseGingivaData(parserMaf, step);

    if ((nJawState == 1 || nJawState == 2)) {
        G_lowGingivaMesh_Base.visible = true;
    } else {
        G_lowGingivaMesh_Base.visible = false;
    }

    if ((nJawState == 1 || nJawState == 2)) {
        G_upperGingivaMesh_Base.visible = true;
    } else {
        G_upperGingivaMesh_Base.visible = false;
    }

    if ((nJawState == 1 || nJawState == 2)) {
        G_lowGingivaMesh.visible = true;
    } else {
        G_lowGingivaMesh.visible = false;
    }

    if ((nJawState == 1 || nJawState == 2)) {
        G_upperGingivaMesh.visible = true;
    } else {
        G_upperGingivaMesh.visible = false;
    }

    if (jawDisplayState == 1) {
        G_lowGingivaMesh.visible = false;
        G_lowGingivaMesh_Base.visible = false;

        G_upperGingivaMesh.visible = true;
        G_upperGingivaMesh_Base.visible = true;
    }

    if (jawDisplayState == -1) {
        G_upperGingivaMesh.visible = false;
        G_upperGingivaMesh_Base.visible = false;

        G_lowGingivaMesh.visible = true;
        G_lowGingivaMesh_Base.visible = true;
    }


}

// // 切换第n步牙龈的显示
// function shiftGingivaVisible(step, nJawState) {

//     vecUpGingiva.forEach(function (value, key) {
//         if (key == step && (nJawState == 1 || nJawState == 2)) {
//             vecUpGingiva.get(key).visible = true;
//         } else {
//             vecUpGingiva.get(key).visible = false;
//         }
//     });
//     vecUpGingiva_Base.forEach(function (value, key) {
//         if (key == step && (nJawState == 1 || nJawState == 2)) {
//             vecUpGingiva_Base.get(key).visible = true;
//         } else {
//             vecUpGingiva_Base.get(key).visible = false;
//         }
//         if (vecUpGingiva.has(step) == false) {
//             createGingiva(step);
//         }
//     });

//     vecLowGingiva.forEach(function (value, key) {
//         if (key == step && (nJawState == -1 || nJawState == 2)) {
//             vecLowGingiva.get(key).visible = true;
//         } else {
//             vecLowGingiva.get(key).visible = false;
//         }
//     });

//     vecLowGingiva_Base.forEach(function (value, key) {
//         if (key == step && (nJawState == -1 || nJawState == 2)) {
//             vecLowGingiva_Base.get(key).visible = true;
//         } else {
//             vecLowGingiva_Base.get(key).visible = false;
//         }
//         if (vecUpGingiva.has(step) == false) {
//             createGingiva(step);
//         }
//     });

//     // if (vecUpGingiva.has(step) == false) {
//     //     createGingiva(step);
//     // }
// }


// 创建调试用模型
function createTestMesh(x, y, z, material) {
    var vertices = [
        new window.THREE.Vector3(1, 1, 1),
        new window.THREE.Vector3(1, 1, -1),
        new window.THREE.Vector3(1, -1, 1),
        new window.THREE.Vector3(1, -1, -1),
        new window.THREE.Vector3(-1, 1, -1),
        new window.THREE.Vector3(-1, 1, 1),
        new window.THREE.Vector3(-1, -1, -1),
        new window.THREE.Vector3(-1, -1, 1)
    ];

    var faces = [
        new window.THREE.Face3(0, 2, 1),
        new window.THREE.Face3(2, 3, 1),
        new window.THREE.Face3(4, 6, 5),
        new window.THREE.Face3(6, 7, 5),
        new window.THREE.Face3(4, 5, 1),
        new window.THREE.Face3(5, 0, 1),
        new window.THREE.Face3(7, 6, 2),
        new window.THREE.Face3(6, 3, 2),
        new window.THREE.Face3(5, 7, 0),
        new window.THREE.Face3(7, 2, 0),
        new window.THREE.Face3(1, 3, 4),
        new window.THREE.Face3(3, 6, 4),
    ];

    var geom = new window.THREE.Geometry();
    // 将顶点和面赋给几何体
    geom.vertices = vertices;
    geom.faces = faces;
    // 计算每个面的法向量
    geom.computeFaceNormals(material);

    var meshMaterial = material;
    if (geom.hasColors) {
        meshMaterial = new window.THREE.MeshPhongMaterial({opacity: geom.alpha, vertexColors: window.THREE.VertexColors});
    }
    var mesh = new window.THREE.Mesh(geom, meshMaterial);
    mesh.position.set(x, y, z);
    return mesh;
}
// 仅播放单颌牙齿模型动画
function plsySingleJawTeeth(jaw, step) {
    vecTeeth.forEach(function (value, key) {
        if (jaw == 1) { // 上颌单独调
            if (key > 30 && key < 50 && (jawDisplayState == -1 || jawDisplayState == 2)) {
                return;
            }
        } else if (jaw == -1) { // 下颌单独调
            if (key > 10 && key < 30 && (jawDisplayState == 1 || jawDisplayState == 2)) {
                return;
            }
        }

        var offset = mapStepinfo_Offset.get(key)[step]; // 偏移量
        var quat = mapStepinfo_Quaternion.get(key)[step]; // 四元数
        vecTeeth.get(key).position.set(offset.x, offset.y, offset.z); // 设定偏移量
        vecTeeth.get(key).quaternion.set(quat.x, quat.y, quat.z, quat.w); // 设定四元数旋转量
    });
}
// 仅播放单颌牙龈模型动画
function plsySingleJawGingiva(jaw, step) {
    var nJawState = jawDisplayState;
    if (jaw == 1) {
        vecUpGingiva.forEach(function (value, key) {
            if (key == step && (nJawState == 1 || nJawState == 2)) {
                vecUpGingiva.get(key).visible = true;
            } else {
                vecUpGingiva.get(key).visible = false;
            }
        });
        if (vecUpGingiva.has(step) == false) {
            createGingiva(step);
        }
        // vecUpGingiva_Base.forEach(function (value, key) {
        //     if (key == step && (nJawState == 1 || nJawState == 2)) {
        //         vecUpGingiva_Base.get(key).visible = true;
        //     } else {
        //         vecUpGingiva_Base.get(key).visible = false;
        //     }
        // });
        // if (vecUpGingiva_Base.has(step) == false) {
        //     createGingiva(step);
        // }
    } else if (jaw == -1) {
        vecLowGingiva.forEach(function (value, key) {
            if (key == step && (nJawState == -1 || nJawState == 2)) {
                vecLowGingiva.get(key).visible = true;
            } else {
                vecLowGingiva.get(key).visible = false;
            }
        });
        if (vecLowGingiva.has(step) == false) {
            createGingiva(step);
        }
        // vecLowGingiva_Base.forEach(function (value, key) {
        //     if (key == step && (nJawState == -1 || nJawState == 2)) {
        //         vecLowGingiva_Base.get(key).visible = true;
        //     } else {
        //         vecLowGingiva_Base.get(key).visible = false;
        //     }
        // });
        // if (vecLowGingiva_Base.has(step) == false) {
        //     createGingiva(step);
        // }
    }

    if (vecUpGingiva.has(step) == false) {
        createGingiva(step);
    }
}
// 面片细分,被细分的模型,细分系数,官方Demo为2
function subdivide(smoothMesh, subdivisions) {
    var modifier = new window.THREE.SubdivisionModifier(subdivisions, false);
    // var modifier = new window.THREE.TessellateModifier(0.1);
    var repeats = subdivisions;
    while (repeats-- > 0) {
        modifier.smooth(smoothMesh.geometry);
        // modifier.modify(smoothMesh.geometry);
    }smoothMesh.geometry.computeFaceNormals();
    smoothMesh.geometry.computeVertexNormals();

    var smoothGeometry = smoothMesh.geometry;
    // for ( var i = 0; i < smoothGeometry.faces.length; i ++ ) {
    //     var face = smoothGeometry.faces[ i ];
    //     for ( var j = 0; j < 3; j ++ ) {
    //         var vertexIndex = face[ faceIndices[ j ] ];
    //         var vertex = smoothGeometry.vertices[ vertexIndex ];
    //         var hue = ( vertex.y / 200 ) + 0.5;
    //         var color = new window.THREE.Color().setHSL( hue, 1, 0.5 );
    //         face.vertexColors[ j ] = color;
    //     }

    // }

    if (smoothMesh.geometry) 
        smoothMesh.geometry.dispose();
    
    smoothMesh.geometry = new window.THREE.BufferGeometry().fromGeometry(smoothGeometry);
}

/********************************** Render ************************************/

function Free() {
    parserMaf = {};
    parserMaf = new window.Module.CParsermaf();
    mafModel = {};
    vecTeeth = new Map();
    vecTeeth_Root = new Map();
    vecAttachment = new Map();
    vecUpGingiva = new Map();
    vecLowGingiva = new Map();
    vecUpGingiva_Base = new Map();
    vecLowGingiva_Base = new Map();
    mapStepinfo_Offset = new Map();
    mapStepinfo_Quaternion = new Map();
    stepBar = [];
    nTotalStep = -1;
    stepIndex = 0;
    jawDisplayState = 2;
    if (geomInitUpper && geomInitUpper.isBufferGeometry){
        geomInitUpper.dispose();
    }
    geomInitUpper = {};
    if (geomInitLower && geomInitLower.isBufferGeometry){
        geomInitLower.dispose();
    }
    geomInitLower = {};
}

export const GetRenderer = function () {
    return renderer;
}

export const GetCamera = function () {
    return camera;
}

export const AddRenderScene = function (object) {
    if (scene != undefined) {
        scene.add(object);
    }
}

export const GetRenderScene = function () {
    return scene;
}

export const RemoveRenderScene = function (object) {
    if (scene != undefined) {
        for(let i=scene.children.length - 1; i >= 0; i--){ 
            let obj = scene.children[i];
            if(obj == object){
                scene.remove(obj); 
            }
        }
    }
}

export const SwitchProjectionMode = function (type) {
    if (camera != undefined && scene != undefined) {
        ww = window.innerWidth;
        wh = window.innerHeight*0.7;
        let cpos = new window.THREE.Vector3(), cup = new window.THREE.Vector3();
        let crot = new window.THREE.Euler();
        cpos.copy(camera.position);
        crot.copy(camera.rotation);
        cup.copy(camera.up);
        if (type == 0) {
            camera = cameraPersp;
            camera.aspect = ww / wh;
            camera.position.copy(cpos);
            camera.rotation.copy(crot);
            camera.up.copy(cup);
            camera.lookAt(cameraTarget);
            camera.updateProjectionMatrix();
        }
        else {
            camera = cameraOrtho;
            camera.position.copy(cpos);
            camera.rotation.copy(crot);
            camera.up.copy(cup);
            camera.lookAt(cameraTarget);
            camera.updateProjectionMatrix();
        }
        if (controls != undefined){
            controls.object = camera;
            controls.panSpeed = (type == 0)?0.3:1.5;
            controls.update();
        }
        renderer.clear();
        renderer.render( scene, camera );
    }
}

export const SetTrackballMode = function (type) {
    if (controls != undefined){
        if (type == 0){
            controls.mouseButtons = { LEFT: window.THREE.MOUSE.ROTATE, MIDDLE: window.THREE.MOUSE.ZOOM, RIGHT: window.THREE.MOUSE.PAN };
        } else {
            controls.mouseButtons = { LEFT: window.THREE.MOUSE.PAN, MIDDLE: window.THREE.MOUSE.ZOOM, RIGHT: window.THREE.MOUSE.ROTATE };
        }
    }
}

export const SetTeethTransparency = function (state) {
    let transparent = state?0.6:1.0;
    vecTeeth.forEach(function (value, key) {
        value.material.transparent = state;
        value.material.opacity = transparent;
    });
    vecTeeth_Root.forEach(function (value, key) {
        value.material.transparent = state;
        value.material.opacity = transparent;
    });
}

export const SetAttachmentTransparency = function (state) {
    let transparent = state?0.6:1.0;
    vecAttachment.forEach(function (value, key) {
        value.material.transparent = state;
        value.material.opacity = transparent;
        value.material.depthWrite = !state;
    });
}

export function GetCallback() {
    return funCallbackObj;
}

export const GetShowJaw = function () {
    return jawDisplayState;
}

/********************************** Boltion Index ************************************/

export const GetBoltonIndex36 = function () {
    let BoltionUR = GetBoltonIndexUR();
    let BoltionUL = GetBoltonIndexUL();
    let BoltionLR = GetBoltonIndexLR();
    let BoltionLL = GetBoltonIndexLL();
    let upper33=0, lower33=0, upper66=0, lower66=0;
    BoltionUR.forEach(function(value) {
        if ((value[0] % 10) <= 3){
            upper33 += value[1];
        }
        upper66 += value[1];
    });
    BoltionUL.forEach(function(value) {
        if ((value[0] % 10) <= 3){
            upper33 += value[1];
        }
        upper66 += value[1];
    });
    BoltionLR.forEach(function(value) {
        if ((value[0] % 10) <= 3){
            lower33 += value[1];
        }
        lower66 += value[1];
    });
    BoltionLL.forEach(function(value) {
        if ((value[0] % 10) <= 3){
            lower33 += value[1];
        }
        lower66 += value[1];
    });

    let bolton36 = [0, 0];
    if (upper33 == 0 || lower33 / upper33 > 0.772){
        bolton36[0] = lower33 - upper33*0.772;
    } else {
        bolton36[0] = upper33 - lower33/0.772;
    }
    if (upper66 == 0 || lower66 / upper66 > 0.913){
        bolton36[1] = lower66 - upper66*0.913;
    } else {
        bolton36[1] = upper66 - lower66/0.913;
    }
    return bolton36;
}

export const GetBoltonIndexUR = function () {
    let ret = [];
    let size = new window.THREE.Vector3();
    for (let key = 11; key != 17; key++){
        let value = vecTeeth.get(key);
        if (value != undefined) {
            if (value.geometry.boundingBox == undefined || value.geometry.boundingBox.isEmpty()){
                value.geometry.computeBoundingBox();
            }
            value.geometry.boundingBox.getSize(size);
            let row = [key, size.x];
            ret.push(row);
        } else {
            let row = [key, 0];
            ret.push(row);
        }
    }
    if (ret.length != 0){
        ret.sort(function(a,b){return a[0]-b[0]});
    }
    return ret;
}

export const GetBoltonIndexUL = function () {
    let ret = [];
    let size = new window.THREE.Vector3();
    for (let key = 21; key != 27; key++){
        let value = vecTeeth.get(key);
        if (value != undefined) {
            if (value.geometry.boundingBox == undefined || value.geometry.boundingBox.isEmpty()){
                value.geometry.computeBoundingBox();
            }
            value.geometry.boundingBox.getSize(size);
            let row = [key, size.x];
            ret.push(row);
        } else {
            let row = [key, 0];
            ret.push(row);
        }
    }
    if (ret.length != 0){
        ret.sort(function(a,b){return a[0]-b[0]});
    }
    return ret;
}

export const GetBoltonIndexLR = function () {
    let ret = [];
    let size = new window.THREE.Vector3();
    for (let key = 41; key != 47; key++){
        let value = vecTeeth.get(key);
        if (value != undefined) {
            if (value.geometry.boundingBox == undefined || value.geometry.boundingBox.isEmpty()){
                value.geometry.computeBoundingBox();
            }
            value.geometry.boundingBox.getSize(size);
            let row = [key, size.x];
            ret.push(row);
        } else {
            let row = [key, 0];
            ret.push(row);
        }
    }
    if (ret.length != 0){
        ret.sort(function(a,b){return a[0]-b[0]});
    }
    return ret;
}

export const GetBoltonIndexLL = function () {
    let ret = [];
    let size = new window.THREE.Vector3();
    for (let key = 31; key != 37; key++){
        let value = vecTeeth.get(key);
        if (value != undefined) {
            if (value.geometry.boundingBox == undefined || value.geometry.boundingBox.isEmpty()){
                value.geometry.computeBoundingBox();
            }
            value.geometry.boundingBox.getSize(size);
            let row = [key, size.x];
            ret.push(row);
        } else {
            let row = [key, 0];
            ret.push(row);
        }
    }
    if (ret.length != 0){
        ret.sort(function(a,b){return a[0]-b[0]});
    }
    return ret;
}

/********************************** Comparison ************************************/

export const GetInitialTeeth = function (jaw) {
    if (jaw){
        if (geomInitUpper && geomInitUpper.isBufferGeometry){
            // return geomInitUpper;
        } else {
            geomInitUpper = new window.THREE.BufferGeometry();
            let geomInit = new window.THREE.Geometry();
            let mat = new window.THREE.Matrix4();
            vecTeeth.forEach(function (value, key) {
                if (key < 30) {
                    let offset = mapStepinfo_Offset.get(key)[0]; // 偏移量
                    let quat = mapStepinfo_Quaternion.get(key)[0]; // 四元数
                    let geom = new window.THREE.Geometry();
                    mat.makeRotationFromQuaternion(quat);
                    mat.setPosition(offset);
                    geom.fromBufferGeometry(value.geometry);
                    geomInit.merge(geom, mat, 0);
                    geom.dispose();
                    geom = null;
                }
            });
            geomInitUpper.fromGeometry(geomInit);
            geomInit.dispose();
            geomInit = null;
        }
        return geomInitUpper;
    } else {
        if (geomInitLower && geomInitLower.isBufferGeometry){
            // return geomInitLower;
        } else {
            geomInitLower = new window.THREE.BufferGeometry();
            let geomInit = new window.THREE.Geometry();
            let mat = new window.THREE.Matrix4();
            vecTeeth.forEach(function (value, key) {
                if (key > 30) {
                    let offset = mapStepinfo_Offset.get(key)[0]; // 偏移量
                    let quat = mapStepinfo_Quaternion.get(key)[0]; // 四元数
                    let geom = new window.THREE.Geometry();
                    mat.makeRotationFromQuaternion(quat);
                    mat.setPosition(offset);
                    geom.fromBufferGeometry(value.geometry);
                    geomInit.merge(geom, mat, 0);
                    geom.dispose();
                    geom = null;
                }
            });
            geomInitLower.fromGeometry(geomInit);
            geomInit.dispose();
            geomInit = null;
        }
        return geomInitLower;
    }
};

/********************************** ArchWidth ************************************/

export const GetAnteriorArchWidth = function (jaw, step) {
    let ret = [];
    let idL = jaw?23:33, idR = jaw?13:43;
    let meshL = vecTeeth.get(idL);
    let meshR = vecTeeth.get(idR);
    if (meshL != undefined && meshR != undefined){
        let ptL = CalcuArchReferencePoint(3, meshL);
        let ptR = CalcuArchReferencePoint(3, meshR);
        let offsetL = mapStepinfo_Offset.get(idL)[step];
        let quatL = mapStepinfo_Quaternion.get(idL)[step];
        let offsetR = mapStepinfo_Offset.get(idR)[step];
        let quatR = mapStepinfo_Quaternion.get(idR)[step];
        ptL.applyQuaternion(quatL).add(offsetL);
        ptR.applyQuaternion(quatR).add(offsetR);
        ret.push(ptL);
        ret.push(ptR);
        // console.log(ptL.sub(ptR).length());
    }
    return ret;
};

export const GetPosteriorArchWidth = function (jaw, step) {
    let ret = [];
    let idL = jaw?26:36, idR = jaw?16:46;
    let meshL = vecTeeth.get(idL);
    let meshR = vecTeeth.get(idR);
    if (meshL != undefined && meshR != undefined){
        let ptL = CalcuArchReferencePoint(6, meshL);
        let ptR = CalcuArchReferencePoint(6, meshR);
        let offsetL = mapStepinfo_Offset.get(idL)[step];
        let quatL = mapStepinfo_Quaternion.get(idL)[step];
        let offsetR = mapStepinfo_Offset.get(idR)[step];
        let quatR = mapStepinfo_Quaternion.get(idR)[step];
        ptL.applyQuaternion(quatL).add(offsetL);
        ptR.applyQuaternion(quatR).add(offsetR);
        ret.push(ptL);
        ret.push(ptR);
        // console.log(ptL.sub(ptR).length());
    }
    return ret;
};

function CalcuArchReferencePoint(type, mesh){
    let archpt = new window.THREE.Vector3();
    let geom = new window.THREE.Geometry();
    geom.fromBufferGeometry(mesh.geometry);
    geom.mergeVertices ();
    if (mesh.geometry.boundingBox == undefined || mesh.geometry.boundingBox.isEmpty()){
        mesh.geometry.computeBoundingBox();
    }
    if (type == 3){
        let refdist = 0;
        geom.vertices.forEach(function (pt) {
            if (pt.z < 0){
                if (Math.abs(pt.x) < 0.5){
                    if (pt.z < refdist) {
                        refdist = pt.z;
                        archpt.copy(pt);
                    }
                }
            }
        });
    }
    else if (type == 6){
        let refdist = -100, maxrng = 0.3*mesh.geometry.boundingBox.max.z;
        geom.vertices.forEach(function (pt) {
            if (pt.z < maxrng){
                let len = pt.length();
                if (len < 1 || Math.abs(pt.z)/len > 0.9659){
                    if (pt.z > refdist) {
                        refdist = pt.z;
                        archpt.copy(pt);
                    }
                }
            }
        });
    }
    return archpt;
}

/********************************** MoveData ************************************/

export const GetTeethId = function () {
    let ret = [];
    vecTeeth.forEach(function (value, key) {
            ret.push(key);
    });
    return ret;
};

export const GetMoveData = function (step, toothid) {
    let ret = [];
    let ptOffset = new window.CVector(), ptRot = new window.CVector();
    if (JSON.stringify(mafModel) == '{}'){
            mafModel = parserMaf.GetModel();
    }
    if (mafModel.GetToothMoveData(toothid, step, ptOffset, ptRot)) {
            ret.push(ptOffset.GetX());
            ret.push(ptOffset.GetY());
            ret.push(ptOffset.GetZ());
            ret.push(ptRot.GetX());
            ret.push(ptRot.GetY());
            ret.push(ptRot.GetZ());
    }
    return ret;
};

/********************************** ToothLabelPosition ************************************/

var toothlabelwidth = CalcuToothidSize();

export const GetToothIdPosition = function (toothid, step) {
      let ret = [new window.THREE.Vector3(), new window.THREE.Vector3(), new window.THREE.Vector3(), new window.THREE.Vector3()];
      let mesh = vecTeeth.get(toothid);
      if (mesh != undefined) {
              if (mesh.geometry.boundingBox == undefined || mesh.geometry.boundingBox.isEmpty()){
                      mesh.geometry.computeBoundingBox();
              }
              let center = new window.THREE.Vector3();
              let size = new window.THREE.Vector3();
              mesh.geometry.boundingBox.getCenter(center);
              mesh.geometry.boundingBox.getSize(size);
              if ((10 < toothid && toothid < 20) || (30 < toothid && toothid < 40)) {
                      ret.map(function(value) {
                              value.setY(mesh.geometry.boundingBox.min.y - 0.1);
                      });
              } else {
                      ret.map(function(value) {
                              value.setY(mesh.geometry.boundingBox.max.y + 0.1);
                      });
              }
              if ((10 < toothid && toothid < 20) || (40 < toothid && toothid < 50)) {
                      ret[0].setX(center.x - toothlabelwidth);
                      ret[1].setX(center.x + toothlabelwidth);
                      ret[2].setX(center.x + toothlabelwidth);
                      ret[3].setX(center.x - toothlabelwidth);
              } else {
                      ret[0].setX(center.x + toothlabelwidth);
                      ret[1].setX(center.x - toothlabelwidth);
                      ret[2].setX(center.x - toothlabelwidth);
                      ret[3].setX(center.x + toothlabelwidth);
              }
              if (toothid < 30) {
                      ret[0].setZ(center.z - size.z*0.2 - toothlabelwidth);
                      ret[1].setZ(center.z - size.z*0.2 - toothlabelwidth);
                      ret[2].setZ(center.z - size.z*0.2 + toothlabelwidth);
                      ret[3].setZ(center.z - size.z*0.2 + toothlabelwidth);
              } else {
                      ret[0].setZ(center.z - size.z*0.2 + toothlabelwidth);
                      ret[1].setZ(center.z - size.z*0.2 + toothlabelwidth);
                      ret[2].setZ(center.z - size.z*0.2 - toothlabelwidth);
                      ret[3].setZ(center.z - size.z*0.2 - toothlabelwidth);
              }
              let offset = mapStepinfo_Offset.get(toothid)[step];
              let quat = mapStepinfo_Quaternion.get(toothid)[step];
              ret.map(function(value) {
                      value.applyQuaternion(quat).add(offset);
              });
      }
      return ret;
}

function CalcuToothidSize() {
      let width = 100;
      let size = new window.THREE.Vector3();
      vecTeeth.forEach(function (value, key) {
              if (value.geometry.boundingBox == undefined || value.geometry.boundingBox.isEmpty()){
                      value.geometry.computeBoundingBox();
              }
              value.geometry.boundingBox.getSize(size);
              width = Math.min(width, size.x*0.3);
              if ((key % 10) > 3) {
                      width = Math.min(width, size.z*0.33);
              }
      });
      return (width > 10)?1.5:width;
}

/************************************ IPR **************************************/

export const GetIPRData = function (jaw) {
    let ret = [];
    if (JSON.stringify(parserMaf) == '{}'){
        return ret;
    }
    let paIPR = new window.CIPRPair();
    let IPRSize = parserMaf.GetIPRPairSize();
    for (let i = 0; i != IPRSize; i++) {
        if (parserMaf.GetIPRPair(i, paIPR)) {
            if ((paIPR.GetToothId1() < 30) == jaw) {
                let IPRData = { toothid1:paIPR.GetToothId1(), toothid2:paIPR.GetToothId2(), step:paIPR.GetStep(), ipr:paIPR.GetIPR() }; 
                ret.push(IPRData);
            }
        }
    }
    return ret;
}

export const GetIPRPosition = function (toothid1, toothid2, step) {
    let ret = [new window.THREE.Vector3(), new window.THREE.Vector3()];
    let mesh1 = vecTeeth.get(toothid1);
    let mesh2 = vecTeeth.get(toothid2);
    if (mesh1 != undefined && mesh2 != undefined) {
        if (mesh1.geometry.boundingBox == undefined || mesh1.geometry.boundingBox.isEmpty()){
            mesh1.geometry.computeBoundingBox();
        }
        if (mesh2.geometry.boundingBox == undefined || mesh2.geometry.boundingBox.isEmpty()){
            mesh2.geometry.computeBoundingBox();
        }
        let offset1 = mapStepinfo_Offset.get(toothid1)[step];
        let offset2 = mapStepinfo_Offset.get(toothid2)[step];
        let quat1 = mapStepinfo_Quaternion.get(toothid1)[step];
        let quat2 = mapStepinfo_Quaternion.get(toothid2)[step];
        let center1 = new window.THREE.Vector3(), center2 = new window.THREE.Vector3(), size1 = new window.THREE.Vector3(), size2 = new window.THREE.Vector3();
        mesh1.geometry.boundingBox.getSize(size1);
        mesh2.geometry.boundingBox.getSize(size2);
        mesh1.geometry.boundingBox.getCenter(center1);
        mesh2.geometry.boundingBox.getCenter(center2);
        if ((10<toothid1 && toothid1<20) || (30<toothid1 && toothid1<40)) {
            center1.y -= size1.y/3.0;
        }
        else {
            center1.y += size1.y/3.0;
        }
        if ((10<toothid2 && toothid2<20) || (30<toothid2 && toothid2<40)) {
            center2.y -= size2.y/3.0;
        }
        else {
            center2.y += size2.y/3.0;
        }
        center1.applyQuaternion(quat1).add(offset1);
        center2.applyQuaternion(quat2).add(offset2);
        let len = 0.5*(size1.z + size2.z);
        ret[0].lerpVectors(center1, center2, size1.x/(size1.x + size2.x));
        let dir = new window.THREE.Vector3();
        dir.set(ret[0].x, ret[0].y, 0);
        dir.normalize();
        let hight = 18.0 / (10.0 + Math.max(toothid1%10, toothid2%10));
        ret[1].subVectors(center2, center1).cross(new window.THREE.Vector3(0,0,-1)).normalize().add(dir).normalize().multiplyScalar(len).add(ret[0]);
        ret[1].z += len * hight /(toothid1<30?-3.0:3.0);
    }
    return ret;
}

/*********************************** Occlusion *************************************/

export const GetOcclusalSurface = function (step) {
    let ret = [];
    if (JSON.stringify(parserMaf) == '{}'){
        return ret;
    }
    let occl = {};
    occl = parserMaf.UpdateCollisionDetection(step);
    if (JSON.stringify(occl) == '{}'){
        return ret;
    }
    let vertex = new window.CVector();
    let mat = new window.THREE.Matrix4();
    let vn, fn, occlSize = occl.GetOcclusalInfoSize();
    for (let i = 0; i != occlSize; i++) {
        let occlInfo = {};
        occlInfo = occl.GetOcclusalInfo(i);
        if (JSON.stringify(occlInfo) == '{}'){
            continue;
        }
        let nFDI = occlInfo.GetToothFDI();
        if (mapStepinfo_Offset.get(nFDI) == undefined || mapStepinfo_Offset.get(nFDI).length <= step){
            continue;
        }
        let geom = new window.THREE.Geometry();
        vn = occlInfo.GetMeshVertex();
        fn = occlInfo.GetMeshFace();
        for (let j = 0; j < vn; j++) {
            if (occlInfo.GetVertexXYZ(vertex, j)) {
                geom.vertices.push(new window.THREE.Vector3(vertex.GetX(), vertex.GetY(), vertex.GetZ()));
            }
        }
        for (let j = 0; j < fn; j++) {
            if (occlInfo.GetFaceIndex(vertex, j)) {
                geom.faces.push(new window.THREE.Face3(vertex.GetX(), vertex.GetY(), vertex.GetZ()));
            }
        }
        let offset = mapStepinfo_Offset.get(nFDI)[step];
        let quat = mapStepinfo_Quaternion.get(nFDI)[step];
        mat.makeRotationFromQuaternion(quat);
        mat.setPosition(offset);
        geom.applyMatrix(mat);
        geom.computeVertexNormals();
        geom.computeFaceNormals();
        let bufferGeom = new window.THREE.BufferGeometry();
        bufferGeom.fromGeometry(geom);
        let occlmesh = { mesh:bufferGeom, depth:occlInfo.GetDepth(), jaw:(nFDI<30) }; 
        ret.push(occlmesh);
        geom.dispose();
        geom = null;
    }
    occl.ReleaseOcclusalInfo();
    occl = null;
    return ret;
}

/*********************************** StepTable *************************************/

export const GetAttachmentRange = function () {
    let ret = [];
    if (JSON.stringify(mafModel) == '{}'){
        mafModel = parserMaf.GetModel();
    }
    let nAttachListSize = mafModel.GetAttachListSize();
    for (let i = 0; i < nAttachListSize; i++) {
        let pShell = mafModel.GetAttachment(i);
        let range = [pShell.GetParentFDI(), pShell.GetAttachmentStartStep(), pShell.GetAttachmentEndStep()];
        ret.push(range);
    }
    return ret;
}

export const GetTeethStepTable = function (toothid) {
    let ret = [];
    if (vecTeeth.get(toothid) != undefined) {
        if (JSON.stringify(mafModel) == '{}') {
            mafModel = parserMaf.GetModel();
        }
        let nShellListSize = mafModel.GetShellListSize();
        for (let i = 0; i < nShellListSize; i++) {
            let pShell = mafModel.GetShell(i);
            if (toothid == pShell.GetToothFDI()) {
                let segment = new window.CMoveSegment();
                if (pShell.GetMoveSegment(segment)) {
                    let segsize = segment.GetSegmentSize();
                    for (let j = 0; j < segsize; j++) {
                        let range = [segment.GetSegmentStart(j), segment.GetSegmentEnd(j), 0];
                        ret.push(range);
                    }
                    // console.log(toothid);
                    // console.log(ret);
                }
                break;
            }
        }
    }
    return ret;
}