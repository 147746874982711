<template>
  <el-dialog
    title="移动量"
    :visible.sync="dialogVisible"
    class="hidden-style"
    width="1207px"
    :modal="modal"
    :close-on-click-modal="clickModal"
  >
    <div class="bolton-box content-border">
      <el-row>
        <span style="margin-right: 24px;color: #333">牙弓:</span>
        <el-radio v-model="dentalArch" label="1">上颌</el-radio>
        <el-radio v-model="dentalArch" label="2">下颌</el-radio>
      </el-row>
      <el-row>
        <table
          cellspacing="0"
          v-show="dentalArch === '1'"
          style="width: 1132px;text-align: center;margin-top: 5px;border-bottom: 1px #a6a6a6 solid;font-size: 13px"
        >
          <tr style="color: #333">
            <td style="width:250px"></td>
            <td v-for="i in teethUpCodes" :key="i" class="teeth-num">{{ i }}</td>
          </tr>
          <tr v-for="(title, index) in teethTitle" :key="index" class="bottom-border">
            <td class="border-style" style="width:240px;text-align: left;padding-left: 15px">{{ title }}</td>
            <td class="border-style" v-for="(item, num) in teethUpData" :key="num" style="width:54px;">
              {{ teethUpData[num].length > 0 ? setNum(teethUpData[num][index], index) : '' }}
            </td>
          </tr>
        </table>

        <table
          cellspacing="0"
          v-show="dentalArch === '2'"
          style="width: 1132px;text-align: center;margin-top: 5px;border-bottom: 1px #a6a6a6 solid;font-size: 13px"
        >
          <tr style="color: #333">
            <td style="width:250px"></td>
            <td v-for="i in teethDownCodes" :key="i" class="teeth-num">{{ i }}</td>
          </tr>
          <tr v-for="(title, index) in teethTitle" :key="index" class="bottom-border">
            <td class="border-style" style="width:240px;text-align: left;padding-left: 15px">{{ title }}</td>
            <td class="border-style" v-for="(item, num) in teethDownData" :key="num" style="width:54px;">
              {{ teethDownData[num].length > 0 ? setNum(teethDownData[num][index], index) : '' }}
            </td>
          </tr>
        </table>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import { GetTeethId, GetMoveData } from '../../../../render/renderES';  // 孙懿修改
import { toDecimal1 } from '@/util/index';
export default {
  name: 'moveWindow',
  data() {
    return {
      dentalArch: '1',
      modal: false, //遮罩层标记
      clickModal: false, //点击空白隐藏标记
      teethTitle: [
        '近中 (+)/远中 (-) mm',
        '颊向 (+)/舌向 (-) mm',
        '拉伸 (+)/压低 (-) mm',
        '冠转矩 颊侧 (+)/舌侧 (-) ',
        '冠轴倾 近中 (+)/远中 (-) ',
        '扭转 近中 (+)/远中 (-) '
      ],
      teethCodesTotal: [],
      teethUpCodes: [],
      teethDownCodes: [],
      teethUpData: [],
      teethDownData: [],
      teethTestData: []
    };
  },
  props: {
    visible: {
      //汇总信息
      type: Boolean
    },
    planIndext: {
      type: Number
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('updateMoveChecked');
        return this.$emit('update:visible', value);
      }
    }
  },
  watch: {
    planIndext() {
      //更新移动量数据
      if (this.visible) {
        this.getTeethData();
      }
    },
    visible() {
      if (this.visible) {
        this.getTeethData();
      }
    }
  },
  methods: {
    setNum(value, index) {
      if (index > 2) {
        return toDecimal1(value) + '°';
      } else {
        return toDecimal1(value);
      }
    },
    getTeethData() {
      this.teethCodesTotal = GetTeethId();
      this.teethUpCodes = this.teethCodesTotal.filter(function(code) {
        return code < 30;
      });
      this.teethDownCodes = this.teethCodesTotal.filter(function(code) {
        return code > 30;
      });
      this.teethUpData = [];
      this.teethDownData = [];
      //上颌移动量
      for (let i = 0; i < this.teethUpCodes.length; i++) {
        this.teethUpData.push(GetMoveData(this.planIndext, this.teethUpCodes[i]));
      }
      //下颌移动量
      for (let j = 0; j < this.teethDownCodes.length; j++) {
        this.teethDownData.push(GetMoveData(this.planIndext, this.teethDownCodes[j]));
      }
    }
  }
};
</script>
<style scoped lang="less">
/deep/ .el-dialog__header {
  background-color: #171719;
  padding: 5px 20px;
  span {
    color: #ffffff;
    font-size: 16px;
  }
  .el-dialog__headerbtn {
    top: 10px;
    i {
      color: #ffffff;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 0px 0px 28px;
}
/deep/ .el-dialog__title {
  line-height: 30px;
}
/deep/ .el-dialog__headerbtn {
  font-size: 20px;
}
/deep/ .el-radio__label {
  color: #666;
}
.color-text {
  color: #409eff;
}
.bolton-box {
  padding: 20px 28px 10px;
  .title {
    padding-bottom: 10px;
  }
}
/deep/ .el-dialog {
  position: fixed;
  bottom: 105px;
  margin-bottom: 0px;
  margin-left: 10px;
}
.hidden-style {
  z-index: 0 !important;
}
.border-style {
  border-right: 1px #80c0fc solid;
  border-top: 1px #a6a6a6 solid;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #666;
  &:first-child {
    border-left: 1px #a6a6a6 solid;
    color: #333;
  }
}
.teeth-num {
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>
