<template>
  <div class="login">
    <pageHeader />
    <!-- 头部 -->
    <div class="options">
      <div class="main">
        <div class="main">
          <ul class="clearfix" style="width: calc(100% - 300px)">
            <li
              v-for="(item, i) in otherOptions"
              :key="i + 7"
              :class="item.isCheck ? 'checkBackground' : ''"
              @click="choiceOther.call(this, i)"
            >
              <div class="image">
                <img :src="item.url" />
              </div>
              <p>{{ item.font }}</p>
            </li>
            <li v-for="(item, i) in options" :key="i" @click="choice.call(this, i, item.visible)">
              <div class="image">
                <img :src="item.url" />
              </div>
              <p>{{ item.font }}</p>
            </li>
          </ul>
          <div class="dropdown" style="width: 300px">
            <span style="color: #fff; margin-right: 5px">当前：</span>
            <el-dropdown type="primary" @command="handleCommand">
              <el-button type="primary">
                {{ designate }}
                <i class="el-icon-caret-bottom"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, i) in schemeArray"
                  :key="i"
                  :command="{
                    approvalAndSubmit: item.approvalAndSubmit,
                    planNo: item.planNo,
                    planId: item.planId,
                    pdfUrl: item.pdfUrl,
                    url: item.url,
                    font: `${item.planNo}. ${schemeType[item.status]}`,
                    status: item.status
                  }"
                >{{ `${item.planNo}. ${schemeType[item.status]}` }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-button v-if="btnflag" @click.native="changeURl(status)" style="margin-left: 50px; padding: 10px">
              {{ status === 3 ? '确认生产' : '批准并确认生产' }}
            </el-button>-->
            <!-- <el-button @click.native="changeURl  type="primary" style=" margin-left: 50px;padding:10px" >批准方案下订单</el-button>
            -->
            <!-- <el-button
              type="primary"
              style="
                margin-left: 10px;
                color: #fff;
                background-color: #1a91ff !important;
                background: #1a91ff !important;
                border-color: #1a91ff !important;
                padding: 15px 20px;
                height: 36px;
                line-height: 0px;
                width: 96px;
              "
              :style="status === 3 ? { background: '#9ECCFB !important' } : {}"
              :disabled="status === 3"
              @click="admitConfirm"
              >批准</el-button
            >-->
          </div>
        </div>
      </div>
      <boltonWindow :visible.sync="boltonVisible" @updateBoltonChecked="updateBoltonChecked" />
      <preferenceWindow
        ref="preferenceWindow"
        :visible.sync="preferenceVisible"
        @updatePreferenceChecked="updatePreferenceChecked"
      />
      <planConfirmModal
        ref="planConfirmModal"
        :visible.sync="planConfirmVisible"
        @admitPlan="admitPlan"
      />
    </div>
    <!-- 中间的选项按钮 -->
    <div class="content" :style="bgColorStyle">
      <rightMenu :planName="designate" :totalstep.sync="totalstep" :planIndext.sync="planIndext" />
      <switchBtton :value.sync="switchvalue" class="switch-btn" />
      <p
        id="loading"
        style="position: fixed; top: 50%; left: 50%; margin: -50px 0 0 -50px; text-align: center"
      ></p>
      <canvas class="scene"></canvas>
      <moveWindow
        :visible.sync="moveVisible"
        :planIndext="planIndext"
        @updateMoveChecked="updateMoveChecked"
      />
      <!-- <ul class="clearfix">
        <li @click="retreat">
          <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/后退.png`)" />
        </li>
        <li @click="last">
          <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/上一步.png`)" />
        </li>
        <li @click="autoAdvance">
          <img :src="[
              isPlay
                ? require(`@/assets/#病例详情#3D方案切图9.9/PC/暂停.png`)
                : require(`@/assets/#病例详情#3D方案切图9.9/PC/开始.png`)
            ]" />
        </li>
        <li @click="next">
          <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/下一步.png`)" />
        </li>
        <li @click="advance">
          <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/前进.png`)" />
        </li>
      </ul>-->
    </div>

    <!-- 下面的进度条 -->
    <div class="foot foot-plan">
      <div class="plan-btn">
        <ul class="clearfix">
          <li @click="retreat">
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/normal (6).png`)" />
          </li>
          <li @click="last">
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/normal (5).png`)" />
          </li>
          <li @click="autoAdvance">
            <img
              :src="[
                isPlay
                  ? require(`@/assets/#病例详情#3D方案切图9.9/PC/normal (1).png`)
                  : require(`@/assets/#病例详情#3D方案切图9.9/PC/normal (2).png`)
              ]"
            />
          </li>
          <li @click="next">
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/normal (3).png`)" />
          </li>
          <li @click="advance">
            <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/normal (4).png`)" />
          </li>
        </ul>
      </div>
      <div class="main">
        <ul class="curTop" v-show="panTop" ref="curTop">
          <div
            v-show="planIndext == 0"
            class="planOne plan"
            :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')})`"
          >
            <span v-show="planIndext == 0" class="num">0</span>
          </div>
          <span class="bottomOne" v-show="!panBottom && panTop">0</span>
          <li
            v-for="(item, i) in planArrayT"
            :key="i"
            :class="planIndext > i ? (item.type == 1 ? 'hscur' : 'cur') : ''"
            @click="planClick.call(this, i)"
            :style="{ width: topwidth + 'px' }"
          >
            <div
              class="plan"
              v-show="planIndext == i + 1"
              :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')})`"
            >
              <span class="num" v-show="planIndext == i + 1">{{ i + 1 }}</span>
            </div>
            <span
              class="bottomIndex"
              v-if="(i + 1) % 5 === 0"
              v-show="!panBottom && panTop"
            >{{ i + 1 }}</span>
          </li>
          <li class="foot_sz">{{ `${planIndext}/${planArrayT.length}` }}</li>
        </ul>
        <!-- 下进度条 -->
        <ul class="curBottom" v-show="panBottom" ref="curBottom">
          <div
            v-show="planIndext == 0 && !panTop && panBottom"
            class="planOne plan"
            :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')})`"
          >
            <span v-show="planIndext == 0" class="num">0</span>
          </div>
          <span class="bottomOne">0</span>
          <li
            v-for="(item, i) in planArrayB"
            :key="i"
            :class="planIndext > i ? (item.type == 1 ? 'hscur' : 'cur') : ''"
            @click="planClick.call(this, i)"
            :style="{ width: bottomwidth + 'px' }"
          >
            <div
              class="plan"
              v-show="planIndext == i + 1 && !panTop && panBottom"
              :style="`background-image:url(${require('@/assets/#病例详情#3D方案切图9.9/PC/进度.png')})`"
            >
              <span class="num" v-show="planIndext == i + 1">{{ i + 1 }}</span>
            </div>
            <span class="bottomIndex" v-if="(i + 1) % 5 === 0">{{ i + 1 }}</span>
          </li>
          <li class="foot_sz">{{ `${planIndext}/${planArrayB.length}` }}</li>
        </ul>
      </div>
      <!-- 上进度条 -->

      <!-- <img :src="require(`@/assets/#病例详情#3D方案切图9.9/PC/未标题-1.png`)" /> -->
    </div>
    <!-- 咬合面lenged -->
    <div class="occlusal_lengend" v-if="occlusalLenged">
      <el-row style="padding: 15px 0px">
        <el-col :span="24" style="display: flex; align-items: center; height: 30px">
          <div
            class="occlusal_circle"
            :style="'background-color:' + $store.state.biteColor.split(',')[0]"
          ></div>
          <div style="margin-left: 10px">0~0.3</div>
        </el-col>
        <el-col :span="24" style="display: flex; align-items: center; height: 30px">
          <div
            class="occlusal_circle"
            :style="'background-color:' + $store.state.biteColor.split(',')[1]"
          ></div>
          <div style="margin-left: 10px">0.3~0.6</div>
        </el-col>
        <el-col :span="24" style="display: flex; align-items: center; height: 30px">
          <div
            class="occlusal_circle"
            :style="'background-color:' + $store.state.biteColor.split(',')[2]"
          ></div>
          <div style="margin-left: 10px">&gt;0.6</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script src="../../render/menuClick.js"></script>
<script>
import Cookies from 'js-cookie';
import { getHashCode } from '@/util/index';

import { getQueryString } from '@/util/validate';
import pageHeader from '@/views/common/FourSmile/pageHeader';
import switchBtton from '@/views/common/FourSmile/switch';
import boltonWindow from '@/views/common/FourSmile/bolton';
import moveWindow from '@/views/common/FourSmile/move';
import rightMenu from '@/views/common/FourSmile/rightMenu';
import preferenceWindow from '@/views/common/FourSmile/preference';
import planConfirmModal from '@/views/common/FourSmile/planConfirmModal';

import { render } from '../../render/renderFourSmile';

import { initStl } from '../../render/renderFourSmile';

import { pointModel } from '../../render/renderFourSmile';

import { playModel } from '../../render/renderFourSmile';

import menuClickMixin from '../../render/menuClick';
import TopMenu from '../../render/renderTopMenu';
import { GetCamera, GetRenderScene, RemoveRenderScene } from '../../render/renderFourSmile';
import { GetShowJaw, shiftAttachVisible, SetAttachmentTransparency } from '../../render/renderFourSmile'; // 孙懿修改

let TIMERSTEP = null;
export default {
  mixins: [menuClickMixin, TopMenu],
  data() {
    return {
      caseId: 1009515,
      designate: `方案1`,
      overlayMesh: {},
      otherOptions: [
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/附件.png`),
          font: '附件',
          visible: 1,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/编号.png`),
          font: '编号',
          visible: 0,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/IPR.png`),
          font: 'IPR',
          visible: 0,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/牙弓宽度.png`),
          font: '牙弓宽度',
          visible: 0,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/测量.png`),
          font: '测量',
          visible: 2,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/网格.png`),
          font: '网格',
          visible: 1,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/叠加.png`), //显示的图片
          font: '叠加', //显示的文字
          visible: 0,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/咬合面.png`),
          font: '咬合面',
          visible: 1,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/移动量.png`),
          font: '移动量',
          visible: 0,
          isCheck: false //是否选中
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/Bolton.png`),
          font: 'Bolton',
          visible: 0,
          isCheck: false //是否选中
        }
        // ,
        // {
        //   url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/偏好.png`),
        //   font: '偏好',
        //   visible: 0,
        //   isCheck: false //是否选中
        // }
      ],
      options: [
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/上颌.png`), //显示的图片
          font: '上颌', //显示的文字
          visible: 1 //0显示双颌，1显示上颌，2显示下颌
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/下颌.png`),
          font: '下颌',
          visible: 2
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/上牙颌面.png`),
          font: '上牙颌面',
          visible: 1
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/下牙颌面.png`),
          font: '下牙颌面',
          visible: 2
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/右侧.png`),
          font: '右侧',
          visible: 0
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/正面.png`),
          font: '正面',
          visible: 1
        },
        {
          url: require(`@/assets/#病例详情#3D方案切图9.9/PC/视角切换图标/左侧.png`),
          font: '左侧',
          visible: 0
        }
      ],
      //上进度进度
      planArrayT: [],
      //下进度进度
      planArrayB: [],
      //上进度索引
      planIndext: 0,
      // 上进度是否显示
      panTop: true,
      // 下进度是否显示
      panBottom: true,
      // 是否自动前进
      autoFlag: {
        flag: false,
        interval: null
      },
      // 方案数组集合
      schemeArray: [],
      // 目前方案状态
      status: 1,
      approvalAndSubmit: false,
      schemeType: ['方案修改中', '方案需修改', '方案待确认', '方案已确认'],
      istop: true,
      topwidth: 0,
      bottomwidth: 0,
      curstep: 0,
      totalstep: [
        [1, 0],
        [2, 0],
        [3, 0],
        [4, 1],
        [5, 1],
        [6, 1],
        [7, 1],
        [8, 1],
        [9, 1],
        [10, 1],
        [11, 1],
        [12, 1]
      ],
      isPlay: false,
      caseUid: '',
      planId: 0,
      token: null,
      btnflag: false,
      switchvalue: 'rotate', //swich按钮value值
      boltonVisible: false, //bolton窗口显示和隐藏
      moveVisible: false, //移动量窗口显示和隐藏
      preferenceVisible: false, //偏好窗口显示和隐藏
      planConfirmVisible: false, //批准方案窗口显示和隐藏
      numberMeshList: {
        up: [],
        bottom: []
      }, //编号mesh对象
      iprMeshLish: [], //切面对象
      occlusalLenged: false //咬合面lenged 显示flag
    };
  },
  async created() {
    // Cookies.set(
    //   'token',
    //   'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDA3OSwidXNlck5hbWUiOiJBMTM1MzUxNzgzMDAiLCJwd2QiOiJhN3lqdGhxSHhNTWppUENhUVM4V1d0S2o5NzRQRkIrWG43czQ1Z3hEOU8zaHJpWW1oL3UzQkJWOWtBZW1ramkxR2tXbGE3Y1ZkRWlqczRpVUs5UkVOUT09IiwidHlwZSI6MX0.bagbC3HMtZ4I8uIkBBSMvIttMeRhoO2xRX4SCI_3CHyaKmGK5IFr4AQ5vAuPu6gRpMmxDN8fCmhjyVppSXTgHw'
    // );
    localStorage.setItem('winWidth', '0');
    this.caseId = getQueryString('caseId');
    this.planId = getQueryString('planId') || 0;
    this.token = getQueryString('token') || null;
    // this.caseId = 1009515;
    this.$store.commit('changeCaseId', this.caseId);
    localStorage.setItem('approvalCaseId', this.caseId);
    if (!this.caseId) {
      // this.$router.replace('/index');
    }
    if (await this.validPlanToken(this.token)) {
      console.log('成功');
      this.plans();
    } else {
      this.$message.error('token过期');
      console.log('失败');
    }
    //设置背景色
    this.$http({
      url: '/plan/getSetting',
      method: 'get'
    }).then(({ data }) => {
      if (data.code == 200) {
        data = JSON.parse(data.data);
        this.$store.commit('changeBgColor', data.bgColor);
        this.$store.commit('changeAttachColor', data.attachColor);
        this.$store.commit('changeBiteColor', data.biteColor);
      }
    });
    this.$store.commit('changeSCopyRight', false)
    //
  },
  beforeMount() {
    if (localStorage.getItem('phone') == '16621097734') {
      this.btnflag = true;
    } else {
      return;
    }

    if (window.location.href.indexOf('easysmile.magicalign') == -1) {
      this.btnflag = true;
    } else {
      this.btnflag = false;
    }
  },
     mounted() { this.$store.commit('changeCopy',true)
    //声明raycaster和mouse变量
    window.raycaster = new THREE.Raycaster();
    window.mouse = new THREE.Vector2();
  },
  components: {
    pageHeader,
    switchBtton,
    boltonWindow,
    rightMenu,
    moveWindow,
    preferenceWindow,
    planConfirmModal
  },
  computed: {
    menuFlag() {
      return {
        planIndext: this.planIndext,
        panTop: this.panTop,
        panBottom: this.panBottom
      };
    },
    bgColorStyle() {
      return { background: this.$store.state.bgColor };
    }
  },
  watch: {
    /**
     * 步骤监听
     */
    menuFlag() {
      this.clearNumberMesh();
      this.numberClick();
      this.clearIprMesh();
      this.iprClick();
      // 牙弓长度
      this.setArch();
      //叠加菜单点击
      this.setContrast();
      //咬合面
      this.setOcclusal();
    }
  },
  methods: {
    validPlanToken(e) {
      return new Promise((res, rej) => {
        if (Cookies.get('token')) {
          res(true);
          return;
        }
        if (!e) {
          res(false);
          return;
        }

        // let baseURL = this.$http.defaults.baseURL;
        // this.$http.defaults.baseURL = this.webservice;
        this.$http({
          url: '/cooperation/easysmile/validPlanToken',
          method: 'post',
          data: {
            token: e
          }
        }).then(({ data }) => {
          // this.$http.defaults.baseURL = baseURL;

          if (data.code == 200) {
            let flag = JSON.parse(data.data);
            if (flag.validToken) {
              res(true);
            } else {
              res(false);
            }
          }
        });
      });
    },
    //初始化
    init() {
      // this.plans();
      //   异步请求数据,用延时器来代替
      let vm = this;
      setTimeout(() => {
        //控制进度条灰色 1 代表灰色 0代表蓝色
        if (vm.totalstep != undefined && vm.totalstep.length > 0) {
          vm.planArrayT = new Array(vm.totalstep.length - 1);
          vm.planArrayB = new Array(vm.totalstep.length - 1);
          for (var j = 0; j < vm.totalstep.length - 1; j++) {
            vm.planArrayT[j] = {
              type: vm.totalstep[j][0]
            };
            vm.planArrayB[j] = {
              type: vm.totalstep[j][1]
            };
          }
        } else {
          vm.planArrayT = [];
          vm.planArrayB = [];
        }
        vm.planIndext = 0;
        vm.topwidth = vm.$refs.curTop.clientWidth / (vm.planArrayT.length + 1);
        vm.bottomwidth = vm.$refs.curBottom.clientWidth / (vm.planArrayB.length + 1);
      }, 200);
    },
    //方案列表
    plans() {
      this.$http({
        url: '/caseInfo/plans',
        method: 'get',
        params: {
          caseId: this.caseId
        }
      }).then(({ data }) => {
        // 接口不通，暂时不用200
        if (data.code == 200) {
          data = JSON.parse(data.data);

          this.designate = `${data[0].planNo}. ${this.schemeType[data[0].status]}`;
          this.schemeArray = data;

          this.status = this.schemeArray[0].status;
          this.approvalAndSubmit = this.schemeArray[0].approvalAndSubmit;
          this.planId = data[0].planId;
          this.$store.commit('changePlanId', data[0].planId);
          this.$store.commit('changePdfUrl', data[0].pdfUrl);
          localStorage.setItem('approvalPlanNo', data[0].planNo);
          console.log('index.vue');
          console.log(data[0]);

          if (window.location.href.indexOf('easysmile.magicalign') != -1) {
            console.log('正確');
            data[0].url = data[0].url.replace(/case.magicalign/, 'easysmile.magicalign');
            console.log(data[0]);
          } else {
            console.log('错误的');
          }

          this.getData(data[0].url);
          // this.getData('./MA21073100013.MAF');
        }
      });
    },
    //批准确认框
    admitConfirm() {
      this.$refs.planConfirmModal.planName = this.designate;
      this.planConfirmVisible = !this.planConfirmVisible;
    },
    //确定批准
    admitPlan() {
      let vm = this;
      this.$http({
        url: '/production/approvalPlan',
        method: 'POST',
        data: {
          caseId: localStorage.getItem('approvalCaseId'), //zgs修改
          planNo: localStorage.getItem('approvalPlanNo') //zgs修改
        }
      }).then(({ data }) => {
        if (data.code == 200) {
          this.$message({
            message: '批准成功',
            type: 'success',
            duration: 1500,
            onClose: () => {}
          });
          vm.$http({
            url: '/caseInfo/plans',
            method: 'get',
            params: {
              caseId: vm.caseId
            }
          }).then(({ data }) => {
            if (data.code == 200) {
              data = JSON.parse(data.data);
              this.schemeArray = data;
            }
          });
          this.status = 3;
          this.designate = this.designate.split('.')[0] + '.方案已确认';
        } else {
          this.$message({
            message: data.msg,
            type: 'error',
            duration: 1500,
            onClose: () => {}
          });
        }
      });
      // this.changeURl(status);
    },
    //bolton窗口关闭后取消bolton选中背景色
    updateBoltonChecked() {
      this.otherOptions[9].isCheck = false;
    },
    //移动量窗口关闭后取消选中背景色
    updateMoveChecked() {
      this.otherOptions[8].isCheck = false;
    },
    //偏好窗口关闭后取消选中背景色
    updatePreferenceChecked() {
      this.otherOptions[10].isCheck = false;
    },
    choiceOther(i) {
      this.otherOptions[i].isCheck = !this.otherOptions[i].isCheck;
      //点击需要处理的菜单
      // var showListIndex = [11, 12, 13, 14, 15, 16, 17];
      // //点击后，恢复其他项的选中状态
      // if (showListIndex.indexOf(i) !== -1) {
      //   for (let j = 0; j < showListIndex.length; j++) {
      //     if (showListIndex[j] !== i) {
      //       this.options[showListIndex[j]].isCheck = false;
      //     }
      //   }
      // }
      //附件菜单点击
      if (i === 0) {
        this.accessoryClick(this.otherOptions[i].isCheck);
      }
      //编号点击
      if (i === 1) {
        this.numberClick();
      }
      if (i === 2) {
        //IPR
        this.iprClick();
      }
      // 牙弓长度
      this.setArch();
      if (i === 4) {
        window.pointsArray = [];
        window.intersectsArray = [];
        window.window_mouse = false;
        window.allPointsArray = [];
        window.scene.remove(window.moveLine);
        window.allMeshObjectArr.forEach(item => {
          RemoveRenderScene(item);
        });
        window.allMeshObjectArr = [];
        document.removeEventListener('mousemove', window.onMouseMove, false);
        document.getElementsByClassName('scene')[0].onclick = null;
        window.moveLine = null;
        if (this.otherOptions[4].isCheck) {
          window.menuData = this.otherOptions;
          document.getElementsByClassName('scene')[0].onclick = window.onMouseDown;
          let vm = this;
          document.getElementsByClassName('scene')[0].onmousedown = function(event) {
            vm.mouseButton(event);
          };
        }
      }
      if (i === 5) {
        //网格
        this.setGroupGrid();
      }
      //叠加菜单点击
      if (i === 6) {
        this.setContrast();
        this.clearNumberMesh();
        this.numberClick(); //编号
        this.iprClick(); //ipr
      }
      if (i === 7) {
        //咬合面
        this.setOcclusal();
      }
      //移动量弹窗
      if (i === 8) {
        this.moveVisible = !this.moveVisible;
      }
      //bolton分析弹窗
      if (i === 9) {
        this.boltonVisible = !this.boltonVisible;
      }
      //偏好弹窗
      if (i === 10) {
        this.$refs.preferenceWindow.setPreference();
        this.preferenceVisible = !this.preferenceVisible;
      }
    },
    //调用3d附件的点击事件    参数：true：选中  false：取消选中
    accessoryClick(checkFlag) {
      console.log(checkFlag);
      //3d TODO
      shiftAttachVisible(!checkFlag); // 孙懿修改
    },
    // 双颌，单颌，左侧，右侧选项按钮点击函数,参数"i"是点中的那张图,从0开始;
    choice(i, visible) {
      // 孙懿修改
      if (i == 0) {
        this.panTop = true;
        this.panBottom = false;
        this.istop = false;
        if (2 == GetShowJaw() || -1 == GetShowJaw()) {
          pointModel(1);
        }
      } else if (i == 1) {
        this.panTop = false;
        this.panBottom = true;
        this.istop = true;
        if (2 == GetShowJaw()) {
          pointModel(1);
          pointModel(1);
        } else if (1 == GetShowJaw()) {
          pointModel(1);
        }
      } else if (i == 2) {
        //上颌显示
        this.panTop = true;
        this.panBottom = false;
        pointModel(6);
      } else if (i == 3) {
        //下颌显示
        this.panTop = false;
        this.panBottom = true;
        pointModel(5);
      } else if (i == 4) {
        //双颌显示
        this.panTop = true;
        this.panBottom = true;
        pointModel(0);
        pointModel(4);
      } else if (i == 5) {
        //双颌显示
        this.panTop = true;
        this.panBottom = true;
        pointModel(0);
        pointModel(3);
      } else if (i == 6) {
        //双颌显示
        this.panTop = true;
        this.panBottom = true;
        pointModel(0);
        pointModel(2);
      }

      // 网格
      // this.setGridRotatex();
    },
    // 方案1，方案2，方案3，选项按钮点击函数,参数"command"是选中的选项。;
    handleCommand(command) {
      this.designate = command.font;
      this.totalstep = [];
      this.status = command.status;
      this.init();
      if (window.location.href.indexOf('easysmile.magicalign') != -1) {
        console.log('正確');
        command.url = command.url.replace(/case.magicalign/, 'easysmile.magicalign');
        console.log(command.url);
      } else {
        console.log('錯誤的');
      }

      this.getData(command.url);
      this.approvalAndSubmit = command.approvalAndSubmit;
      console.log(command);
      this.planId = command.planId;
      this.$store.commit('changePlanId', command.planId);
      this.$store.commit('changePdfUrl', command.pdfUrl);
      localStorage.setItem('approvalPlanNo', command.planNo);
      this.otherOptions.forEach(item => {
        item.isCheck = false;
      });
    },
    //上进度条点击
    planClick(i) {
      this.curstep = i + 1;
      this.gotocurstep();
    },
    // 后退
    retreat() {
      this.curstep = 0;
      this.gotocurstep();
    },
    // 上一步
    last() {
      this.curstep--;
      this.gotocurstep();
    },
    // 自动前进
    autoAdvance() {
      this.isPlay = !this.isPlay;
      if (this.isPlay) {
        if (this.curstep == this.totalstep.length - 1) {
          this.curstep = -1;
          TIMERSTEP = setInterval(() => {
            if (this.curstep >= this.totalstep.length - 1) {
              this.isPlay = false;
              this.curstep = this.totalstep.length - 1;
              this.planIndext = this.curstep;
              clearInterval(TIMERSTEP);
              TIMERSTEP = null;
              return;
            }
            this.curstep++;
            this.planIndext = this.curstep;
            playModel(this.curstep);
            SetAttachmentTransparency(this.otherOptions[1].isCheck); // 孙懿修改
            render();
          }, 500);
        } else {
          TIMERSTEP = setInterval(() => {
            if (this.curstep >= this.totalstep.length - 1 || this.curstep < 0) {
              this.isPlay = false;
              this.curstep = this.totalstep.length - 1;
              this.planIndext = this.curstep;
              clearInterval(TIMERSTEP);
              TIMERSTEP = null;
              return;
            }
            this.curstep++;
            this.planIndext = this.curstep;
            playModel(this.curstep);
            SetAttachmentTransparency(this.otherOptions[1].isCheck); // 孙懿修改
            render();
          }, 500);
        }
      } else {
        clearInterval(TIMERSTEP);
        TIMERSTEP = null;
      }
    },
    // 下一步
    next() {
      this.curstep++;
      this.gotocurstep();
    },
    // 前进
    advance() {
      this.curstep = this.totalstep.length - 1;
      this.gotocurstep();
    },
    async getData(url) {
      let loadingdom = document.getElementById('loading');
      loadingdom.innerText = '方案加载中……';
      this.caseUid = getHashCode(url).toString(36);
      // console.error(this.caseUid, initStl);
      initStl(this.caseUid, url, false);

      TIMERSTEP = setInterval(() => {
        if (localStorage.getItem(this.caseUid) == null) {
          console.log('未拿到数据');
        } else {
          loadingdom.innerText = '';
          this.totalstep = JSON.parse(localStorage.getItem(this.caseUid));
          localStorage.removeItem(this.caseUid);
          clearInterval(TIMERSTEP);
          TIMERSTEP = null;
          this.init();
          window.camera = GetCamera();
          window.GetCamera = GetCamera;
          window.scene = GetRenderScene();
          this.initTopMenu();
        }
      }, 100);
    },
    gotocurstep() {
      if (this.curstep < 0) {
        this.curstep = 0;
      } else if (this.curstep >= this.totalstep.length) {
        this.curstep = this.totalstep.length - 1;
      }
      this.planIndext = this.curstep;
      playModel(this.curstep);
      SetAttachmentTransparency(this.otherOptions[1].isCheck); // 孙懿修改
    },
    changeURl(status) {
      // if(status === 3) return;
      if (this.approvalAndSubmit) {
        this.$message({
          message: '方案已经批准并下订单，不能再次批准',
          type: 'success'
        });
        return;
      }

      this.LoadingOpen();
      this.$http({
        url: '/production/approvalPlan',
        method: 'POST',
        data: {
          caseId: localStorage.getItem('approvalCaseId'), //病例ID
          planNo: localStorage.getItem('approvalPlanNo')
        }
      })
        .then(({ data }) => {
          this.LoadingClose();
          if (data.code == 200) {
            if (status != 3) {
              this.$message({
                message: '批准成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                }
              });
            }
            this.$router.push('./hoxuxinjian/approvalPlan');
            this.$store.commit('indexAdd', ['新建订单', '/hoxuxinjian/approvalPlan']);
          } else {
            this.$message({
              message: data.msg,
              type: 'error',
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit('refreshDataList');
              }
            });
          }
          this.httpflag = true;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style scoped lang="less">
.login {
  min-height: calc(100vh - 77px);
  overflow: hidden;
  position: relative;
  background: #dfe0e2;

  /deep/.dlbgimg {
    height: 100%;
    display: block;
    margin-top: -80px;
  }

  .topdhlg {
    width: 100%;
    height: 80px;
    background: #353b43;
    position: relative;
    box-shadow: 0px 5px 20px #353b43;
  }
}

.options {
  height: 90px;
  width: 100%;
  margin-top: 56px;
  background: #171719;

  .main {
    width: 100%;
    position: relative;
    height: 100%;

    /deep/ .el-row {
      width: 70%;
      margin: 0 auto;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .lgimg {
      display: block;
      width: 123px;
      position: absolute;
      z-index: 99;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    ul {
      height: 100%;

      li {
        float: left;
        width: 5.3%;
        height: 100%;
        position: relative;
        font-size: 14px;
        border-right: #424243 1px solid;

        .image {
          width: 100%;
          height: 75%;

          position: relative;

          img {
            position: absolute;
            left: 50%;
            top: 65%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 130%;
            border-radius: 10px;
          }
        }

        &:hover {
          background: #196dba;
          cursor: pointer;
        }
        &:first-child {
          margin-left: 20px;
        }
        &:last-child {
          margin-right: 0;
          border-right: 0px;
        }

        p {
          margin-top: -10px;
          width: 100%;
          text-align: center;
          color: #fff;
        }
      }
    }

    /deep/.dropdown {
      position: absolute;
      z-index: 99;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 42px;

      .el-button-group {
        height: 35px;
      }

      .el-button--primary {
        height: 100%;
        width: 135px;
        padding: 10px 10px 12px 12px;
        background-color: #c9cdd3 !important;
        background: #c9cdd3 !important;
        border: none !important;
        color: #1a91ff;
        font-size: 16px;
      }

      .el-dropdown__caret-button {
        width: 25px;
        padding: 10px 5px 12px 0px;
      }
    }
  }
}
.checkBackground {
  background-color: #1a91ff;
}

.content {
  height: calc(100vh - 146px);
  min-height: 390px;
  > img {
    display: block;
    margin: 0 auto;
  }

  position: relative;

  ul {
    width: 380px;
    position: fixed;
    bottom: 110px;
    left: 50%;
    margin-left: -190px;

    li {
      float: left;
      margin: 0 20px;
    }
  }
}

.foot {
  position: absolute;
  bottom: 0;
  background: #f9f9f9;
  border-top: 1px solid #434345;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  z-index: 1;

  .hide {
    display: none;
  }

  .main ul {
    // width: 90%;
    margin-bottom: 10px;
    // height: 50px;
    position: relative;
    display: flex;
    justify-content: space-between;

    .plan {
      position: absolute;
      height: 30px;
      width: auto;
      top: -30px;
      right: -12.44px;
      // transition: all 0.1s linear;
      width: 25px;
      height: 30px;
      background-size: cover;
    }

    .planOne {
      left: -12.44px;
    }

    .num {
      position: absolute;
      // top: -30px;
      width: 100%;
      text-align: center;
      color: #fff;
      // transition: all 0.1s linear;
    }

    .bottomOne {
      position: absolute;
      bottom: -30px;
      left: 0;
    }

    .bottomIndex {
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
    }

    li {
      width: 50px;
      height: 10px;
      border: 1px solid #1175d2;
      border-radius: 3px;
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
    }

    .foot_sz {
      top: -5px;
      width: 40px;
      border: none;
      font-size: 14px;
      color: #1175d2;
    }

    .cur {
      background: #1175d2;
      // transition: all 0.1s linear;
    }

    .hscur {
      background: #ccc;
      border: 1px solid #ccc;
    }
  }

  img {
    width: 100%;
  }
}

.foot-plan {
  background: #2e2f33;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .plan-btn {
    ul {
      width: 370px;
      li {
        float: left;
        margin: 0 20px;
      }
    }
  }
  .main {
    width: 1450px;
    margin-left: 0;
  }
}
.switch-btn {
  position: fixed;
  top: 210px;
  left: 20px;
}
.occlusal_lengend {
  position: fixed;
  bottom: 100px;
  left: 0px;
  width: 110px;
  height: 120px;
  background-color: #ffffff;
  border-radius: 4px;
}
.occlusal_circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 15px;
}
</style>
