<template>
  <div class="file-history-box" v-show="show">
    <el-row style="padding-bottom: 44px">
      <el-col :span="24">
        <span class="go-back" @click="hideEvent">&lt;返回</span>
        <span class="tile">文件记录</span>
      </el-col>
    </el-row>
    <div style="height: calc(100vh - 450px); padding-right: 10px">
      <el-scrollbar style="height: 100%" ref="scrollFileHistory">
        <el-row class="block-box" v-for="(item, key) in data" :key="key">
          <el-col :span="24" class="block-title">
            <span>{{ key }}</span>
          </el-col>
          <el-col :span="6" v-for="file in item" :key="file.id">
            <div style="margin-bottom: 10px">
              <el-image
                class="img"
                :src="calcImgUrl(file.fileUrl)"
                :fit="calcImgFit(file.fileUrl)"
                style="border: 1px solid #1a91ff"
              />
            </div>
            <div style="margin-bottom: 44px">
              <div style="padding-right: 15px">
                <span
                  :title="getFileName(file.fileUrl)"
                  style="display: inline-block; max-width: 110px; overflow-x: hidden; white-space: nowrap"
                  >{{ getFileName(file.fileUrl) }}</span
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
export default {
  name: 'fileHistory',
  data() {
    return {
      fileLogo: require('@/assets/#病例详情#3D方案切图9.9/PC/右侧/文件.png')
    };
  },
  props: {
    show: {
      type: Boolean
    },
    data: {
      type: Object
    }
  },
  watch: {
    show() {
      if (this.show) {
        let vm = this;
        this.$nextTick(() => {
          vm.scrollToBottom();
        });
      }
    }
  },
  methods: {
    hideEvent() {
      this.$emit('update:show', false);
    },
    scrollToBottom() {
      let vm = this;
      setTimeout(() => {
        vm.$refs.scrollFileHistory.wrap.scrollTo({ top: vm.$refs.scrollFileHistory.wrap.scrollHeight });
      }, 100);
    }
  },
  computed: {
    getFileName() {
      return (url) => {
        return url.split('/').pop();
      };
    },
    calcImgUrl() {
      let vm = this;
      return (url) => {
        let name = url.split('.').pop().toLowerCase();
        if (['png', 'jpg', 'jpeg'].indexOf(name) != -1) {
          return url;
        } else {
          return vm.fileLogo;
        }
      };
    },
    calcImgFit() {
      return (url) => {
        let name = url.split('.').pop().toLowerCase();
        if (['png', 'jpg', 'jpeg'].indexOf(name) != -1) {
          return 'fill';
        } else {
          return 'none';
        }
      };
    }
  }
};
</script>
<style lang="less" scoped>
.file-history-box {
  height: 100%;
  padding: 24px 20px 10px;
  .go-back {
    display: block;
    float: left;
    cursor: pointer;
    color: #1a91ff;
  }
  .tile {
    display: block;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    font-family: 'SourceHanSansCN-Light';
  }
  .go-back:hover {
    color: #1a91ff;
  }

  .block-box {
    .block-title {
      margin-bottom: 12px;
      color: #ffffff;
      font-family: 'SourceHanSansCN-Light';
    }
    .img {
      width: 84px;
      height: 84px;
    }
    .name {
      margin-bottom: 44px;
      color: c9cdd3;
      font-family: 'SourceHanSansCN-Regular';
    }
  }
}
</style>