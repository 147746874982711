<template>
  <textarea style="resize: none" rows="8" v-model="textAreaContent" class="input-box" />
</template>
<script>
export default {
  name: 'areatextComponent',
  props: {
    value: {
      type: String
    }
  },
  computed: {
    textAreaContent: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.input-box {
  font-size: 16px;
  font-family: 'SourceHanSans-CN-Regular';
}
</style>
